<template>
  <client-only>
    <template v-if="showTxHistory && isConnected && address">
      <div>
        <y-btn
          bg
          width="132"
          height="42"
          size="small"
          class="account-button"
          @click="handleWalletBtnClick"
        >
          {{ walletBtnLabel }}
        </y-btn>
        <YTxHistory v-model="showTxDialog"></YTxHistory>
      </div>
    </template>
    <template v-else>
      <el-popover
        v-if="isConnected && address"
        placement="bottom"
        trigger="click"
      >
        <button class="account-button__logout" @click="handleLogout()">
          {{ $t('base.logout') }}
        </button>
        <template #reference>
          <y-btn bg width="132" height="42" size="small" class="account-button">
            {{ walletBtnLabel }}
          </y-btn>
        </template>
      </el-popover>
    </template>
  </client-only>
</template>
<script setup lang="ts">
const props = defineProps({
  showTxHistory: {
    type: Boolean,
    default: false,
  },
})
const { disconnectWallet } = useWallet()
const { address, isConnected } = toRefs(useWalletStore())
const { t } = useCustomI18n()
const { lookupAddress } = useENS()

const showTxDialog = ref(false)

const { refreshTrades } = useTrades()
const handleWalletBtnClick = () => {
  if (!address.value) return
  refreshTrades()
  showTxDialog.value = true
}

const walletBtnLabel = computed(() => {
  if (!isConnected || !address.value) return t('labels.connect-wallet')
  const ensName = lookupAddress(address.value).value.state
  return ensName?.length
    ? ensName
    : address.value.slice(0, 7) + '...' + address.value.slice(-4)
})

const handleLogout = () => {
  disconnectWallet()
}
</script>

<style lang="postcss" scoped>
.account-button__logout {
  display: block;
  width: 100%;
  text-align: center;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;

  &:hover {
    color: var(--yellow);
  }
}
</style>
