<template>
  <div v-if="!loading" class="settings-select-container">
    <el-dropdown popper-class="settings-select-dropdown">
      <span class="selected-settings">
        <el-icon size="20" class="settings-icon">
          <Setting />
        </el-icon>
        <span class="settings-name">Settings</span>
        <el-icon class="el-icon--right">
          <el-icon-arrow-down />
        </el-icon>
      </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item class="--selected">
            <span class="settings-item">
              <div style="margin-right: 10px">BULLET mode</div>
              <y-switch
                v-model="bulletModeState"
                @change="handleBulletModeChange"
              />
            </span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>
<script lang="ts" setup>
import { Setting } from '@element-plus/icons-vue'
import { useBulletModeStore } from './../../../store/bulletMode'

const loading = ref(false)

const bulletModeStore = useBulletModeStore()

const handleBulletModeChange = (value: any) => {
  bulletModeStore.updateBulletMode(value)
}

const bulletModeState = computed(() => {
  return bulletModeStore.enabled
})

onMounted(() => {
  bulletModeStore.initialize()
})
</script>
<style lang="postcss" scoped>
.settings-select-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}
.selected-settings {
  display: flex;
  align-items: center;
  padding: 8px;
  color: var(--yellow);
  cursor: pointer;
  outline: none;
  & .el-icon--right {
    margin-left: 8px;
    font-size: 10px;
    color: var(--yellow);
    transform: scale(1.5);
    & svg {
      width: 10px;
      height: 10px;
    }
  }
  &.--invalid {
    background: #ff616113;
    border: 1px solid var(--red);
    border-radius: 10px;
    & .settings-name {
      color: var(--red);
    }
    & .el-icon--right {
      color: var(--red);
    }
  }
}

.settings-name {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
}

:global(.settings-select-dropdown .el-dropdown-menu) {
  background-color: #26262b;
  border-radius: 16px;
}
:global(.settings-select-dropdown.el-popper.is-light) {
  background-color: #26262b;
  border: 0.5px solid #777777;
  border-radius: 16px;
}
:global(.settings-select-dropdown .el-popper__arrow::before) {
  content: none;
}

:global(.settings-select-dropdown .el-dropdown-menu__item.--selected) {
  color: var(--yellow);
}
:global(
    .settings-select-dropdown .el-dropdown-menu__item:hover:not(.is-disabled)
  ) {
  color: var(--yellow);
  background-color: transparent;
}
.settings-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}
.settings-icon {
  margin-right: 4px;
  color: var(--yellow);
}

@media screen and (max-width: 768px) {
  .settings-select-container {
    justify-content: flex-start;
  }
  .settings-name {
    display: none;
  }
  .selected-settings {
    padding-right: 0;
    padding-left: 0;
    & .el-icon--right {
      color: var(--yellow);
    }
  }
}
</style>
