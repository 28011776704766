import { type ChainId } from 'deorderbook-sdk'

interface SwitchChainOptions {
  showNotification: boolean
}
export default async function useSwitchChain(
  chainId: ChainId,
  onSuccess?: () => void,
  options: SwitchChainOptions = { showNotification: true },
) {
  if (!useIsClient()) return
  const { chains } = useChains()
  const chain = chains.value.find((c) => c.chainId === chainId)

  if (chain !== undefined) {
    const { setCurrentChain } = useChains()

    if (window.ethereum?.request) {
      const res = await window.ethereum
        .request({
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId: `0x${chain.chainId.toString(16)}`,
            },
          ],
        })
        .catch((err) => {
          logTxError(err)
          return err
        })

      if (options?.showNotification) {
        if (String(res) === 'null') {
          useNotify({
            type: 'success',
          })
        } else {
          useNotify({
            message: 'Error while trying to switch network!',
            type: 'error',
          })
        }
      }
      if (isTxRejectedError(res)) return

      setCurrentChain(chain.chainId)
      onSuccess?.()

      return {
        result: res,
      }
    } else {
      window.location.reload()
    }
  }
}
