import { Alchemy, type AlchemySettings } from 'alchemy-sdk'
import { ChainId } from 'deorderbook-sdk'
/**
 * Hook that returns Alchemy Provider based on the current chainId. Default is mainnet.
 */
export default function useAlchemy() {
  const { currentChain } = toRefs(useChainStore())
  const { getChainBy } = useChainStore()

  const sepoliaAlchemy = computed(() => {
    return new Alchemy({
      apiKey: getChainBy({ id: ChainId.ETH_SEPOLIA })?.rpcKey,
      network: getChainBy({ id: ChainId.ETH_SEPOLIA })?.alchemyNetwork,
    })
  })

  const mainnetAlchemy = computed(() => {
    return new Alchemy({
      apiKey: getChainBy({ id: ChainId.ETH_MAINNET })?.rpcKey,
      network: getChainBy({ id: ChainId.ETH_MAINNET })?.alchemyNetwork,
    })
  })

  const alchemy = computed(() => {
    if (currentChain.value?.chainId === ChainId.ETH_SEPOLIA) {
      return sepoliaAlchemy.value
    }
    return mainnetAlchemy.value
  })

  const alchemySettings = computed<AlchemySettings>(() => {
    if (currentChain.value?.chainId === ChainId.ETH_SEPOLIA) {
      return {
        apiKey: getChainBy({ id: ChainId.ETH_SEPOLIA })?.rpcKey,
        network: getChainBy({ id: ChainId.ETH_SEPOLIA })?.alchemyNetwork,
      }
    }
    return {
      apiKey: getChainBy({ id: ChainId.ETH_MAINNET })?.rpcKey,
      network: getChainBy({ id: ChainId.ETH_MAINNET })?.alchemyNetwork,
    }
  })

  return { alchemy, alchemySettings, sepoliaAlchemy, mainnetAlchemy }
}
