import { defineStore } from 'pinia'
import { Big } from 'big.js'
import dayjs from 'dayjs'
import { gql } from '@urql/core'
import { ChainId } from 'deorderbook-sdk'
import useBackendClient from '@base/composables/useBackendClient'
import { onWalletReady } from '@base/composables/onWalletReady'

export interface FormattedToken {
  name: string
  symbol: string
  address: string
  priceUSD: string
}

export interface PriceCacheItem {
  priceUSD: string
  updatedAt: number
}

export interface FetchBulletPriceParams {
  type: 'uBULLET' | 'sellTokenBULLET'
  strikePrice: string // divided by 1e18
  exerciseTimestamp: string
}

interface BulletPriceResponse {
  code: 0 | 1 // 0: success, 1: error
  desc: string // error message if code is 1, otherwise 'ok'
  data: { price: number }
}

interface TokenPrice {
  token: string
  avg_price_usd: number
}

const GET_TOKEN_PRICES = gql`
  query getTokenPrices {
    derived_token_price {
      token
      avg_price_usd
    }
  }
`
const initStoreState = () => {
  const loading = ref(false)
  const formattedTokens = ref<FormattedToken[]>([])
  // TODO: tmp hard code
  const tokenDOB = ref<FormattedToken>({
    priceUSD: '0',
  } as FormattedToken)
  const tokenUSDC = ref<FormattedToken>({
    priceUSD: '1',
  } as FormattedToken)
  const tokenSellToken = ref<FormattedToken>({
    priceUSD: '0',
  } as FormattedToken)
  const tokenWETH = ref<FormattedToken>({
    priceUSD: '0',
  } as FormattedToken)
  const tokenETH = ref<FormattedToken>({
    priceUSD: '0',
  } as FormattedToken)
  const tokenUHODL = ref<FormattedToken>({
    priceUSD: '1',
  } as FormattedToken)
  const tokenSellTokenHODL = ref<FormattedToken>({
    priceUSD: '0',
  } as FormattedToken)
  const tokenUBullet = ref<FormattedToken>({
    priceUSD: '0',
  } as FormattedToken)
  const tokenSellTokenBullet = ref<FormattedToken>({
    priceUSD: '0',
  } as FormattedToken)
  const tokenUSniper = ref<FormattedToken>({
    priceUSD: '0',
  } as FormattedToken)
  const tokenSellTokenSniper = ref<FormattedToken>({
    priceUSD: '0',
  } as FormattedToken)

  const updateDuration = 15
  return {
    loading,
    tokenDOB,
    tokenUSDC,
    tokenSellToken,
    tokenWETH,
    tokenETH,
    tokenUHODL,
    tokenSellTokenHODL,
    tokenUBullet,
    tokenSellTokenBullet,
    tokenUSniper,
    tokenSellTokenSniper,
    formattedTokens,
    updateDuration,
  }
}

export const useTokensStore = defineStore('tokens', () => {
  const { mainnetClient } = useBackendClient()
  const config = useRuntimeConfig()
  const {
    loading,
    tokenDOB,
    tokenUSDC,
    tokenSellToken,
    tokenWETH,
    tokenETH,
    tokenUHODL,
    tokenSellTokenHODL,
    tokenUBullet,
    tokenSellTokenBullet,
    tokenUSniper,
    tokenSellTokenSniper,
    formattedTokens,
    updateDuration,
  } = initStoreState()
  let updatedAt = 0

  onWalletReady(
    () => {
      actionRefreshTokensPrice()
    },
    {
      status: 'setup',
    },
  )

  const getTokenPriceFromList = (list: TokenPrice[], token: string) => {
    const price =
      list.find((price) => price.token === token)?.avg_price_usd ?? 0
    return Big(price).toFixed()
  }

  async function actionRefreshTokensPrice(force = false) {
    if (
      force ||
      (loading.value === false && dayjs().unix() - updatedAt >= updateDuration)
    ) {
      loading.value = true
      updatedAt = dayjs().unix()

      const { data } = await mainnetClient.value.query(GET_TOKEN_PRICES, {})
      const tokenPrices: TokenPrice[] = data?.derived_token_price ?? []

      tokenSellToken.value.priceUSD = getTokenPriceFromList(
        tokenPrices,
        'token-wbtc',
      )
      tokenSellTokenHODL.value.priceUSD = getTokenPriceFromList(
        tokenPrices,
        'token-wbtc',
      )
      tokenUSDC.value.priceUSD = getTokenPriceFromList(
        tokenPrices,
        'token-usdc',
      )
      tokenUHODL.value.priceUSD = getTokenPriceFromList(
        tokenPrices,
        'token-usdc',
      )
      tokenWETH.value.priceUSD = getTokenPriceFromList(tokenPrices, 'token-eth')
      tokenETH.value.priceUSD = getTokenPriceFromList(tokenPrices, 'token-eth')
      tokenDOB.value.priceUSD = getTokenPriceFromList(tokenPrices, 'token-dob')
      tokenETH.value.priceUSD = getTokenPriceFromList(tokenPrices, 'token-eth')
      tokenWETH.value.priceUSD = getTokenPriceFromList(tokenPrices, 'token-eth')
      loading.value = false
    }
  }

  /**
   * Map[key]price, eg: ["0x12412412..."]"12.324"
   * key: sniper address
   */
  const sniperPrice = ref(new Map<string, PriceCacheItem>())

  /**
   * @description Get Sniper price by address, type and strike price
   * @param type - uSNIPER or sellTokenSniper
   * @param strikePrice - Strike price (with decimals)
   * @param address - Sniper address
   * @returns PriceCacheItem ref
   */
  function getSniperPrice(
    type: 'uSNIPER' | 'sellTokenSNIPER',
    strikePrice: string,
    address: string,
  ): Ref<PriceCacheItem | undefined> {
    const target = sniperPrice.value.get(address)
    if (
      target === undefined ||
      (target && target.updatedAt + updateDuration <= dayjs().unix())
    ) {
      if (type === 'uSNIPER') {
        if (
          Big(divStrikePrice(strikePrice)).gt(tokenSellToken.value.priceUSD)
        ) {
          const price = (
            Number(tokenSellToken.value.priceUSD) /
            Big(divStrikePrice(strikePrice)).toNumber()
          ).toFixed()
          sniperPrice.value.set(address, {
            priceUSD: price,
            updatedAt: dayjs().unix(),
          })
        } else {
          sniperPrice.value.set(address, {
            priceUSD: '1',
            updatedAt: dayjs().unix(),
          })
        }
      } else if (type === 'sellTokenSNIPER') {
        if (
          Big(divStrikePrice(strikePrice)).gt(tokenSellToken.value.priceUSD)
        ) {
          sniperPrice.value.set(address, {
            priceUSD: tokenSellToken.value.priceUSD,
            updatedAt: dayjs().unix(),
          })
        } else {
          sniperPrice.value.set(address, {
            priceUSD: Big(divStrikePrice(strikePrice)).toFixed(),
            updatedAt: dayjs().unix(),
          })
        }
      }
    }
    return computed(() => {
      return sniperPrice.value.get(address)
    })
  }

  const getFetchBulletPriceParams = (params: FetchBulletPriceParams) => {
    const { type, strikePrice, exerciseTimestamp } = params
    let bulletName: 'uBullet' | 'eBullet' | 'bBullet'
    let currency: 'BTC' | 'ETH'
    const { chainId } = useChains()

    if (chainId.value === ChainId.SCROLL_SEPOLIA) {
      // TODO: In the future, it is also necessary to judge scroll
      currency = 'ETH'
      bulletName = type === 'uBULLET' ? 'uBullet' : 'eBullet'
    } else {
      currency = 'BTC'
      bulletName = type === 'uBULLET' ? 'uBullet' : 'bBullet'
    }
    return {
      bulletName,
      currency,
      strikePrice,
      exerciseTimestamp,
    }
  }

  /**
   * @description Fetch a bullet price from DeOrderBook backend API. Used for {@link fetchBulletPrice}
   * @param {FetchBulletPriceParams} params - { type, strikePrice, exerciseTimestamp }
   * @returns {Promise<string>} Bullet price ('0' if not found)
   */
  const fetchBulletPriceAsync = async (
    params: FetchBulletPriceParams,
  ): Promise<string> => {
    const { bulletName, currency, strikePrice, exerciseTimestamp } =
      getFetchBulletPriceParams(params)
    const res = (await $fetch(
      `${config.public.DEORDERBOOK_BACKEND_URL}/api/core/price/?bullet_name=${bulletName}_${exerciseTimestamp}_${strikePrice}&currency=${currency}`,
    )) as BulletPriceResponse

    return (res?.data?.price ?? 0).toString()
  }

  /**
   * @description Fetch a bullet price from DeOrderBook backend API. Uses {@link fetchBulletPriceAsync}
   * @param {FetchBulletPriceParams} params - { type, strikePrice, exerciseTimestamp }
   * @returns {Promise<string>} Bullet price ('0' if not found or error)
   */
  const getBulletPrice = async (
    params: FetchBulletPriceParams,
  ): Promise<string> => {
    try {
      return await fetchBulletPriceAsync(params)
    } catch (e) {
      console.error(e)
      return '0'
    }
  }

  // [ Bullet Price ]
  const {
    fetchState: fetchBulletPrice,
    refreshAllState: refreshAllBulletPrice,
    getCaches: getBulletPriceCaches,
  } = useAsyncCache((...args: [FetchBulletPriceParams]) => {
    return fetchBulletPriceAsync(args[0])
  }, '0')

  return {
    loading,
    tokenDOB,
    tokenSellToken,
    tokenWETH,
    tokenETH,
    tokenUSDC,
    tokenUHODL,
    tokenSellTokenHODL,
    tokenUSniper,
    tokenSellTokenSniper,
    tokenUBullet,
    tokenSellTokenBullet,
    getSniperPrice,
    formattedTokens,
    actionRefreshTokensPrice,
    // fetch bullet price
    fetchBulletPrice,
    refreshAllBulletPrice,
    getBulletPriceCaches,
    // fetch bullet price raw async function
    fetchBulletPriceAsync,
    // get bullet price raw async function with error handling
    getBulletPrice,
  }
})
