<template>
  <client-only>
    <el-dropdown
      class="language-select-dropdown"
      :class="classes"
      popper-class="header__i18n"
    >
      <span class="i18n__dropdown" :class="classes">
        {{ $i18n.locale.toUpperCase() }}
        <el-icon class="el-icon--right">
          <el-icon-arrow-down />
        </el-icon>
      </span>
      <template #dropdown>
        <el-dropdown-menu>
          <NuxtLink :to="switchLocalePath('en')">
            <el-dropdown-item
              command="en"
              :aria-selected="$i18n.locale === 'en'"
              @click="onLocaleClick('en')"
            >
              English
            </el-dropdown-item>
          </NuxtLink>
          <NuxtLink :to="switchLocalePath('ru')">
            <el-dropdown-item
              command="ru"
              :aria-selected="$i18n.locale === 'ru'"
              @click="onLocaleClick('ru')"
            >
              Русский
            </el-dropdown-item>
          </NuxtLink>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </client-only>
</template>

<script setup lang="ts">
import { ArrowDown as ElIconArrowDown } from '@element-plus/icons-vue'
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/ru'
import 'dayjs/locale/tr'

const props = defineProps({
  mobile: {
    type: Boolean,
    default: false,
  },
})
const classes = computed(() => ({
  '--is-mobile': props.mobile,
}))

const switchLocalePath = useSwitchLocalePath()

const onLocaleClick = (lang: string) => dayjs.locale(lang)
</script>

<style lang="postcss" scoped>
.i18n__dropdown {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  color: var(--yellow);
  cursor: pointer;
  outline: none;
  & .el-icon--right {
    margin-left: 8px;
    font-size: 10px;
    color: var(--yellow);
    transform: scale(1.5);
    & svg {
      width: 10px;
      height: 10px;
    }
  }
}
/* TODO: Dropdown menu style fix */
:global(.header__i18n .el-dropdown-menu) {
  /* background color of dropdown component pop-up */
  background-color: #26262b;
  border-radius: 16px;
}
:global(.header__i18n.el-popper.is-light) {
  /* background color of dropdown component pop-up */
  background-color: #26262b;
  /* border of dropdown component pop-up */
  border: 0.5px solid #777777;
  border-radius: 16px;
}
:global(.header__i18n .el-popper__arrow::before) {
  /* disable dropdown arrow */
  content: none;
}
:global(.header__i18n .el-dropdown-menu__item.is-disabled) {
  & .network-item {
    opacity: 0.75;
  }
}
:global(.header__i18n .el-dropdown-menu__item[aria-selected='true']) {
  color: #ffc550;
  pointer-events: none;
}
:global(.header__i18n .el-dropdown-menu__item:hover:not(.is-disabled)) {
  color: #ffc550;
  background-color: transparent;
}

@media screen and (max-width: 1024px) {
  .language-select-dropdown {
    &:not(.--is-mobile) {
      display: none;
    }
  }
}
</style>
