<template>
  <div class="more-navlink-container">
    <el-dropdown
      popper-class="more-navlink-dropdown"
      trigger="hover"
      :placement="props.mobile ? 'bottom' : 'bottom-start'"
    >
      <span class="selected-navlink">
        {{ label }}
        <el-icon class="el-icon--right">
          <IcBaselineKeyboardArrowDown />
        </el-icon>
      </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item
            v-for="navlink in links"
            :key="navlink.name"
            :disabled="navlink?.isSoon"
          >
            <span class="menu-item">
              <a
                :href="navlink?.isSoon ? '#' : navlink.path"
                :target="navlink?.target ?? ''"
                :disabled="navlink?.isSoon"
                class="nav-link-item"
                :class="navlink?.isSoon ? '--disabled' : ''"
              >
                {{
                  `${$t(navlink.locale)}${navlink?.isSoon ? ' (soon™)' : ''}`
                }}
                <BiBoxArrowUpRight
                  v-if="!navlink?.isSoon"
                  width="12"
                  height="12"
                  style="margin-left: 4px"
                />
              </a>
            </span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>
<script lang="ts" setup>
import BiBoxArrowUpRight from '~icons/bi/box-arrow-up-right'
import IcBaselineKeyboardArrowDown from '~icons/ic/baseline-keyboard-arrow-down'

const props = defineProps({
  mobile: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: 'More',
  },
  links: {
    type: Array as PropType<NavLink[]>,
    default: () => [
      {
        path: 'https://explorer.deorderbook.com',
        name: 'Explorer',
        active: false,
        activeClass: 'no-active',
        locale: 'header.explorer',
        target: '_blank',
        isExternal: true,
      },
      {
        path: 'https://docs.deorderbook.com',
        name: 'Docs',
        active: false,
        activeClass: 'no-active',
        locale: 'header.docs',
        target: '_blank',
        isExternal: true,
      },
      {
        path: 'https://bazaar.deorderbook.com',
        name: 'Bazaar',
        active: false,
        activeClass: 'no-active',
        locale: 'header.bazaar',
        target: '_blank',
        isExternal: true,
      },
      {
        path: 'https://stats.deorderbook.com',
        name: 'Stats',
        active: false,
        activeClass: 'no-active',
        locale: 'header.stats',
        target: '_blank',
        isExternal: true,
      },
    ],
  },
})
</script>
<style lang="postcss" scoped>
.more-navlink-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.selected-navlink {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--yellow);
  cursor: pointer;
  border-radius: 4px;
  outline: none;
  opacity: 0.75;
  transition: all 200ms ease-in-out;
  & .el-icon--right {
    margin-left: 4px;
    font-size: 10px;
    color: var(--yellow);
    transform: scale(2);
  }
  &:hover {
    opacity: 1;
  }
}
.nav-link-item {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: var(--yellow);
  transition: all 200ms;
  &:not(.active):not(.--disabled) {
    opacity: 0.9;
    &:hover {
      opacity: 1;
    }
  }
  &.--disabled {
    color: #777777;
    pointer-events: none;
    opacity: 1;
  }
}

/* TODO: Dropdown menu style fix */
:global(.more-navlink-dropdown .el-dropdown-menu) {
  /* background color of dropdown component pop-up */
  background-color: #26262b;
  border-radius: 16px;
}
:global(.more-navlink-dropdown.el-popper.is-light) {
  /* background color of dropdown component pop-up */
  background-color: #26262b;
  /* border of dropdown component pop-up */
  border: 0.5px solid #777777;
  border-radius: 16px;
}
:global(.more-navlink-dropdown .el-popper__arrow::before) {
  /* disable dropdown arrow */
  content: none;
}
:global(.more-navlink-dropdown .el-dropdown-menu__item.is-disabled) {
  & .menu-item {
    opacity: 0.75;
    & a.nav-link-item {
      color: #777777 !important;
      pointer-events: none;
      opacity: 1 !important;
    }
  }
}
:global(.more-navlink-dropdown .el-dropdown-menu__item.--selected) {
  color: var(--yellow);
}
:global(
    .more-navlink-dropdown .el-dropdown-menu__item:hover:not(.is-disabled)
  ) {
  color: var(--yellow);
  background-color: transparent;
}
.menu-item {
  display: flex;
  align-items: center;
  min-width: 120px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}
.network-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

@media screen and (max-width: 425px) {
  .network-name {
    display: none;
  }
  .selected-navlink {
    & .el-icon--right {
      color: var(--white);
    }
    & .network-icon {
      margin-right: 0px;
    }
  }
}
</style>
