<!-- eslint-disable max-lines -->
<template>
  <div class="item__column-detail">
    <div class="detail__header">
      <h6 class="header__action">
        {{ info.label }}
      </h6>
      <span
        v-if="info.tag"
        class="header__action-type"
        :class="`is-${info.tag}`"
      >
        {{ info.tag }}
      </span>
      <ExplorerLink :url="info.explorerLink" class-name="header__explorer" />
    </div>
    <div class="detail__card">
      <div v-for="{ key, value } in info.values" :key="key" class="card__row">
        <div class="row__label">
          <p>{{ $t(detailLabel[key as keyof typeof detailLabel]) }}</p>
          <span
            v-if="
              (detailLabel[key as keyof typeof detailLabel] as string) ===
              'labels.deordered'
            "
            class="label__info"
          >
            ({{ $t('ui.tx-history.what-ull-receive') }})
          </span>
        </div>
        <div class="detail-rows">
          <div v-for="(val, index) in value" :key="index" class="row__value">
            <p v-if="val?.pool">
              {{
                checkTokenExist(val?.pool)
                  ? getOriginalSymbol(val?.pool as AllTokenType)
                  : val?.pool
              }}
            </p>
            <div v-if="val?.amount" class="value__token">
              <div class="token__row">
                <img
                  v-if="val.token"
                  :src="getTokenIcon(val?.token!)"
                  alt=""
                  class="token__symbol"
                />
                <p v-if="val?.amount" class="token__amount">
                  {{ val?.amount }}&nbsp;
                </p>
                <span v-if="val?.token">
                  {{ getOriginalSymbol(val?.token) }}
                </span>
              </div>
              <div v-if="val?.strike" class="value__sniper">
                <p class="sniper__label">{{ $t('labels.strike') }}:</p>
                <span class="sniper__value">{{ val?.strike }}</span>
                <p class="sniper__label">{{ $t('labels.expiry') }}:</p>
                <span class="sniper__value">
                  {{ val?.expiry }}
                </span>
              </div>
              <span v-if="val?.usd" class="value__usd">
                {{ val?.usd }}
              </span>
            </div>
            <div v-if="val?.lockUntil" class="value__lock-until">
              <p>{{ val?.lockUntil }}</p>
              <span
                v-if="val?.countdownDays && val?.countdownDays > 0"
                class="lock-until__countdown"
              >
                &nbsp;({{ val?.countdownDays }}&nbsp;{{ $t('labels.days') }})
              </span>
            </div>
            <div v-if="val?.apy" class="value__apr">
              <p>{{ val?.apy }}</p>
            </div>
            <div v-if="val?.fees !== undefined" class="value__token">
              <div class="token__row">
                <img
                  v-if="val?.fees"
                  :src="getTokenIcon(val?.feeToken!)"
                  alt=""
                  class="token__symbol"
                />
                <p v-if="val?.fees !== null" class="token__amount">
                  {{ val?.fees }}&nbsp;
                </p>
                <img
                  v-else
                  src="@base/assets/img/free-icon.svg"
                  alt="free-icon"
                  class="free-icon"
                />
                <span v-if="val?.feeToken">
                  {{ getOriginalSymbol(val?.feeToken) }}
                </span>
              </div>
              <span v-if="val?.feesUSD !== null" class="value__usd">
                {{ val?.feesUSD }}&nbsp;
              </span>
            </div>
            <div v-if="val?.fees2 !== undefined" class="value__token">
              <div class="token__row">
                <img
                  v-if="val?.fees2 && val?.fee2Token"
                  :src="getTokenIcon(val?.fee2Token!)"
                  alt=""
                  class="token__symbol"
                />
                <p v-if="val?.fees2 !== null" class="token__amount">
                  {{ val?.fees2 }}&nbsp;
                </p>
                <img
                  v-else
                  src="@base/assets/img/free-icon.svg"
                  alt="free-icon"
                  class="free-icon"
                />
                <span v-if="val?.fee2Token">
                  {{ getOriginalSymbol(val?.fee2Token) }}
                </span>
              </div>
              <span v-if="val?.fees2USD !== null" class="value__usd">
                {{ val?.fees2USD }}&nbsp;
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import dayjs from 'dayjs'
import { type PropType } from 'vue'
import type { Symbols, AllTokenType } from 'deorderbook-sdk/types'
import { DETAIL_LABEL_MAP } from './utils'
import ExplorerLink from '@base/components/others/ExplorerLink.vue'
import { type TokenSymbols } from '@base/composables/useTokens'
import { formatStrikePrice } from '@base/utils/number'
import { type FormatTrade } from '@base/types/trade'
import type { Bullet, Sniper } from '@base/types/sniperPools'
import type { Token } from '@base/types/tokens'

const props = defineProps({
  data: {
    type: Object as PropType<FormatTrade>,
    default: () => ({}),
  },
})
const { t } = useCustomI18n()
const { data } = toRefs(props)
const { getTokenIcon, divDecimals, getOriginalSymbol, checkTokenExist } =
  useTokens()
const transaction = computed<FormatTrade>(() => data.value)

const detailLabel = computed(() => {
  return DETAIL_LABEL_MAP[transaction.value.label]
})

interface DetailType {
  pool?: string | null
  token?: TokenSymbols | null
  amount?: string | null
  usd?: string | null
  strike?: string | null
  expiry?: string | null
  lockUntil?: string | null
  countdownDays?: number | null
  apy?: string | null
  fees?: string | null
  feeToken?: TokenSymbols | null
  feesUSD?: string | null
  fees2?: string | null
  fee2Token?: TokenSymbols | null
  fees2USD?: string | null
}

// eslint-disable-next-line max-lines-per-function, complexity
const getDetailByKey = (key: string) => {
  let obj = [] as DetailType[]

  const sniperData = {
    strike: formatStrikePrice(transaction.value.strikePrice ?? '0'),
    expiry: dayjs(Number(transaction.value?.exerciseTimestamp + '000')).format(
      'D MMM, YYYY',
    ),
  }

  if (key === 'pool') {
    obj = [
      {
        pool: transaction.value.pool,
      } as const,
    ]
  } else if (
    key === 'underToken' &&
    transaction.value.underToken &&
    transaction.value.underTokenAmount &&
    transaction.value.underTokenUSD
  ) {
    obj = [
      {
        token: transaction.value.underToken,
        amount: useTokenNumberFormat(
          divDecimals(
            transaction.value.underTokenAmount,
            transaction.value.underToken,
          ).value,
          {
            token: transaction.value.underToken,
          },
        ),
        usd: useUSDFormat(transaction.value?.underTokenUSD, {
          showApprox: true,
        }),
      } as const,
    ]
  } else if (
    key === 'outToken' &&
    transaction.value.outTokens.at(0)?.token !== null &&
    transaction.value.outTokens.at(0)?.token !== undefined &&
    transaction.value.outTokens.at(0)?.amount &&
    transaction.value.outTokens.at(0)?.usd
  ) {
    const _sniperData = ['SNIPER', 'BULLET'].some((x) =>
      transaction.value.outTokens.at(0)?.token?.endsWith(x),
    )
      ? sniperData
      : {}
    obj = [
      {
        token: transaction.value.outTokens.at(0)?.token as
          | Token
          | Sniper
          | Bullet,
        amount: useTokenNumberFormat(
          divDecimals(
            transaction.value.outTokens.at(0)?.amount ?? 0,
            transaction.value.outTokens.at(0)?.token as
              | Symbols
              | Token
              | Sniper
              | Bullet,
          ).value,
          {
            token: transaction.value.outTokens.at(0)?.token as
              | TokenSymbols
              | 'SNIPER'
              | 'BULLET',
          },
        ),
        usd: useUSDFormat(transaction.value.outTokens.at(0)?.usd ?? 0, {
          showApprox: true,
        }),
        ..._sniperData,
      } as const,
    ]
  } else if (
    key === 'outToken2' &&
    transaction.value.outTokens.at(1)?.token &&
    transaction.value.outTokens.at(1)?.amount &&
    transaction.value.outTokens.at(1)?.usd
  ) {
    const _sniperData = ['SNIPER', 'BULLET'].some((x) =>
      transaction.value.outTokens.at(1)?.token?.endsWith(x),
    )
      ? sniperData
      : {}
    obj = [
      {
        token: transaction.value.outTokens.at(1)?.token as
          | Token
          | Sniper
          | Bullet,
        amount: useTokenNumberFormat(
          divDecimals(
            transaction.value.outTokens.at(1)?.amount ?? 0,
            transaction.value.outTokens.at(1)?.token as
              | Symbols
              | Token
              | Sniper
              | Bullet,
          ).value,
          {
            token: transaction.value.outTokens.at(1)?.token as
              | TokenSymbols
              | 'SNIPER'
              | 'BULLET',
          },
        ),
        usd: useUSDFormat(transaction.value.outTokens.at(1)?.usd ?? 0, {
          showApprox: true,
        }),
        ..._sniperData,
      } as const,
    ]
  } else if (key === 'inToken') {
    if (transaction.value.inTokens.length > 1) {
      if (
        transaction.value.inTokens.filter(
          (x) => x.token === transaction.value.inTokens.at(0)?.token,
        ).length === transaction.value.inTokens.length
      ) {
        const totalAmount = transaction.value.inTokens.reduce((acc, cur) => {
          return acc + Number(cur.amount ?? '0')
        }, 0)
        const totalUsd = transaction.value.inTokens.reduce((acc, cur) => {
          return acc + Number(cur.usd ?? '0')
        }, 0)
        const token = transaction.value.inTokens.at(0)?.token
        obj = [
          {
            token: token as Token | Sniper | Bullet,
            amount: useTokenNumberFormat(
              divDecimals(
                totalAmount ?? '0',
                token as Symbols | Token | Sniper | Bullet,
              ).value,
              {
                token: token as TokenSymbols | 'SNIPER' | 'BULLET',
              },
            ),
            usd: useUSDFormat(totalUsd ?? '0', {
              showApprox: true,
            }),
          },
        ]
      } else {
        obj = transaction.value.inTokens.map((inToken) => ({
          token: inToken.token as Token | Sniper | Bullet,
          amount: useTokenNumberFormat(
            divDecimals(
              inToken.amount ?? '0',
              inToken.token as Symbols | Token | Sniper | Bullet,
            ).value,
            {
              token: inToken.token as TokenSymbols | 'SNIPER' | 'BULLET',
            },
          ),
          usd: useUSDFormat(inToken.usd ?? '0', {
            showApprox: true,
          }),
        }))
      }
    } else {
      const inToken = transaction.value.inTokens.at(0)
      const _sniperData = ['SNIPER', 'BULLET'].some((x) =>
        inToken?.token?.endsWith(x),
      )
        ? sniperData
        : {}
      if (inToken && inToken?.token && inToken?.amount && inToken?.usd) {
        obj = [
          {
            token: inToken?.token as Token | Sniper | Bullet,
            amount: useTokenNumberFormat(
              divDecimals(
                inToken?.amount ?? '0',
                inToken?.token as Symbols | Token | Sniper | Bullet,
              ).value,
              {
                token: inToken?.token as TokenSymbols | 'SNIPER' | 'BULLET',
              },
            ),
            usd: useUSDFormat(inToken.usd ?? '0', {
              showApprox: true,
            }),
            ..._sniperData,
          } as const,
        ]
      } else {
        obj = [{ ...sniperData } as const]
      }
    }
  } else if (key === 'unstaked') {
    const inToken = transaction.value.inTokens.find((x) =>
      x.token?.endsWith('SNIPER'),
    )
    if (inToken && inToken.token && inToken.amount && inToken.usd) {
      obj = [
        {
          token: inToken?.token as Token | Sniper | Bullet,
          amount: useTokenNumberFormat(
            divDecimals(
              inToken?.amount ?? 0,
              inToken?.token as Symbols | Token | Sniper | Bullet,
            ).value,
            {
              token: inToken?.token as TokenSymbols | 'SNIPER' | 'BULLET',
            },
          ),
          usd: useUSDFormat(inToken?.usd ?? 0, {
            showApprox: true,
          }),
          ...sniperData,
        } as const,
      ]
    } else {
      obj = [{ ...sniperData } as const]
    }
  } else if (key === 'unwinded') {
    const inToken = transaction.value.inTokens.find((x) =>
      x.token?.endsWith('HODL'),
    )
    if (inToken && inToken.token && inToken.amount && inToken.usd) {
      obj = [
        {
          token: inToken?.token as Token | Sniper | Bullet,
          amount: useTokenNumberFormat(
            divDecimals(
              inToken?.amount ?? 0,
              inToken?.token as Symbols | Token | Sniper | Bullet,
            ).value,
            {
              token: inToken?.token as TokenSymbols | 'SNIPER' | 'BULLET',
            },
          ),
          usd: useUSDFormat(inToken?.usd ?? 0, {
            showApprox: true,
          }),
        } as const,
      ]
    }
  } else if (key === 'claimed') {
    const inToken = transaction.value.inTokens.find((x) =>
      x.token?.endsWith('DOB'),
    )
    if (inToken && inToken.token && inToken.amount && inToken.usd) {
      obj = [
        {
          token: inToken?.token as Token | Sniper | Bullet,
          amount: useTokenNumberFormat(
            divDecimals(
              inToken?.amount ?? 0,
              inToken?.token as Symbols | Token | Sniper | Bullet,
            ).value,
            {
              token: inToken?.token as TokenSymbols | 'SNIPER' | 'BULLET',
            },
          ),
          usd: useUSDFormat(inToken?.usd ?? 0, {
            showApprox: true,
          }),
        } as const,
      ]
    }
  } else if (key === 'lockUntil') {
    obj = [
      {
        lockUntil: dayjs(Number(transaction.value?.lockUntil + '000')).format(
          'MMM D YYYY, HH:mm',
        ),
        countdownDays: getCountdownDays(transaction.value?.lockUntil ?? '0'),
      } as const,
    ]
  } else if (key === 'apy') {
    obj = [
      {
        apy: transaction.value.apy,
      } as const,
    ]
  } else if (key === 'fees') {
    obj = [
      {
        fees:
          transaction.value?.fees && transaction.value?.feeToken
            ? useTokenNumberFormat(
                divDecimals(
                  transaction.value?.fees ?? 0,
                  transaction.value.feeToken as
                    | Symbols
                    | Token
                    | Sniper
                    | Bullet,
                ).value,
                {
                  token: transaction.value.feeToken as
                    | TokenSymbols
                    | 'SNIPER'
                    | 'BULLET',
                },
              )
            : null,
        feeToken: transaction.value.feeToken as Token | Sniper | Bullet,
        feesUSD: transaction.value?.feesUSD
          ? useUSDFormat(transaction.value.feesUSD ?? 0, {
              showApprox: true,
            })
          : null,
        fees2:
          transaction.value?.fees2 && transaction.value?.fee2Token
            ? useTokenNumberFormat(
                divDecimals(
                  transaction.value?.fees2 ?? 0,
                  transaction.value.fee2Token as
                    | Symbols
                    | Token
                    | Sniper
                    | Bullet,
                ).value,
                {
                  token: transaction.value.fee2Token as
                    | TokenSymbols
                    | 'SNIPER'
                    | 'BULLET',
                },
              )
            : undefined,
        fee2Token: transaction.value.fee2Token
          ? (transaction.value.fee2Token as Token | Sniper | Bullet)
          : null,
        fees2USD: transaction.value?.fees2USD
          ? useUSDFormat(transaction.value.fees2USD ?? 0, {
              showApprox: true,
            })
          : null,
      } as const,
    ]
  }
  return computed(() => obj)
}

const getCountdownDays = (timestamp: string) => {
  return Math.ceil((Number(timestamp) - Date.now() / 1000) / 60 / 60 / 24)
}

const getInfoLabel = (tx: Ref<FormatTrade>) => {
  const label = tx.value.label
  const hasClaimedDOB =
    tx.value.inTokens.filter((x) => x.token === 'DOB').length > 0

  if (label === 'Collect' && hasClaimedDOB) {
    return t('base.claim-collect')
  }

  if (label === 'Unstake' && hasClaimedDOB) {
    return t('base.unstake-claim')
  }

  if (label === 'Unwind' && hasClaimedDOB) {
    return t('base.unwind-claim')
  }

  return label
}

const info = computed(() => {
  return {
    label: getInfoLabel(transaction),
    tag:
      transaction.value.label === 'DeOrder'
        ? transaction.value.action === 'DeOrderSell'
          ? 'sell'
          : 'buy'
        : null,
    explorerLink: `/tx/${transaction.value.txHash}`,
    values: Object.keys(detailLabel.value)
      .map((key) => ({
        key,
        value: getDetailByKey(key).value,
      }))
      .filter((x) => Object.keys(x.value).length > 0),
  }
})
</script>
<!-- eslint-disable max-lines -->
<style lang="postcss" scoped>
.item__column-detail {
}
.detail__header {
  display: flex;
  align-items: center;
  height: var(--row1-height);
}
.header__action {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #ffffff;
}
.header__action-type {
  width: 36px;
  height: 17px;
  margin-left: 8px;
  font-size: 10px;
  font-weight: 600;
  line-height: 15px;
  text-align: center;
  border-radius: 43px;
  &.is-buy {
    color: #32b845;
    background: linear-gradient(0deg, #13651f 0%, #07310d 60.94%, #042108 100%);
    border: 0.5px solid #257932;
  }
  &.is-sell {
    color: #f65046;
    background: linear-gradient(
      0deg,
      #c61d10 -6.13%,
      #7f1d17 23.93%,
      #510906 49.55%
    );
    border: 0.5px solid #ba2d24;
  }
}
.header__explorer {
  margin-left: 16px;
}
.detail__card {
  padding: 16px;
  margin-top: 24px;
  background: #212126;
  border: 1px solid #4d4d4d;
  border-radius: 16px;
}
.detail-rows {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.card__row {
  display: flex;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
}
.row__label {
  width: 50%;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #ffffff;
}
.label__info {
  font-size: 11px;
  font-weight: 400;
  line-height: 16px;
  color: #ffffff;
  opacity: 0.5;
}
.row__value {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #ffffff;
}
.token__row {
  display: flex;
  align-items: center;
}
.value__usd {
  margin-top: 4px;
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.5;
}
.value__sniper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  margin-top: 4px;
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  color: #ffffff;
}
.sniper__label {
  opacity: 0.5;
  &:not(:first-child) {
    margin-left: 8px;
  }
}
.sniper__value {
  margin-left: 4px;
}
.value__lock-until {
  display: flex;
}
.lock-until__countdown {
  color: #64acff;
}
.token__symbol {
  width: 24px;
  height: 24px;
}
.token__amount {
  margin-left: 8px;
}
.item__column-status {
}
.status__bar--success {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 117px;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #00df9a;
  background: rgba(0, 223, 154, 0.2);
  border-radius: 30px;
}
.bar__point {
  width: 10px;
  height: 10px;
  background: #00df9a;
  border-radius: 50%;
}
@media screen and (max-width: 425px) {
  .header__action {
    font-size: 16px;
    line-height: 1.25;
  }
  .header__action-type {
    font-size: 8px;
    line-height: 12px;
  }
  .card__row {
    flex-direction: column;
  }
  .row__value {
    margin-top: 8px;
    font-size: 14px;
    line-height: 18px;
  }
  .row__label {
    width: auto;
    font-size: 12px;
    line-height: 14px;
  }
  .label__info {
    font-size: 8px;
    line-height: 12px;
  }
  .detail__card {
    padding: 12px;
    margin-top: 12px;
  }
  .explorer__text {
    font-size: 12px;
    line-height: 14px;
  }
  .token__symbol {
    width: 16px;
    height: 16px;
  }
}
</style>
