<template>
  <div class="flow-card">
    <h6 class="flow-title">{{ title }}</h6>
    <div class="flow-data">
      <template v-for="(item, index) in data" :key="item.token">
        <AmountBlock :data="item" :show-plus-icon="index !== 0" />
      </template>
    </div>
  </div>
</template>
<script setup lang="ts">
import AmountBlock from './AmountBlock.vue'
import { type FlowData } from './utils'

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  data: {
    type: Array<FlowData>,
    default: [] as FlowData[],
  },
})
const { data } = toRefs(props)
</script>
<style lang="postcss" scoped>
.flow-card {
  text-align: center;
}
.flow-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.5;
}
.flow-data {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px 0;
  margin-top: 4px;
  background: #212126;
  border: 1px solid #4d4d4d;
  border-radius: 16px;
}
.flow-in {
  margin-top: 24px;
  text-align: center;
}
.in__card {
  padding: 12px 16px;
  margin-top: 4px;
  background: #212126;
  border: 1px solid #4d4d4d;
  border-radius: 16px;
}
.is-under-token {
  padding: 8px 0;
  margin: 16px 4px 0;
  border: 1px solid #4d4d4d;
  border-radius: 8px;
}
@media screen and (max-width: 425px) {
  .in__label {
    font-size: 12px;
    line-height: 14px;
  }
  .flow-title {
    font-size: 12px;
    line-height: 14px;
  }
}
</style>
