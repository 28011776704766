<template>
  <div v-loading="isLoading" class="TxHistoryList">
    <template v-if="formatTrades.length !== 0">
      <div v-for="item in formatTrades" :key="item.id" class="trade__list">
        <h5 v-if="item.isFirstTxOfDay" class="list__day">
          {{ dayjs(Number(item.timestamp + '000')).format('MMMM D, YYYY') }}
        </h5>
        <TxHistoryListItem :data="item" class="list__item" />
      </div>
    </template>
    <h5 v-else class="list__empty">{{ $t('ui.tx-history.no-transaction') }}</h5>
  </div>
</template>
<script setup lang="ts">
import dayjs from 'dayjs'
import TxHistoryListItem from './TxHistoryListItem.vue'

const { trades, isLoading } = useTrades()

const formatTrades = computed(() => {
  // PM: not need support bullet-related for now
  return trades.value
    .filter(
      (x) =>
        !['BULLETClaim', 'SellBULLET', 'BuyBULLET', 'SoldBULLET'].includes(
          x.action,
        ),
    )
    .map((x, index: number) => {
      // The first transaction event of the day
      let isFirstTxOfDay = false
      if (index === 0) {
        isFirstTxOfDay = true
      } else {
        isFirstTxOfDay = !dayjs(
          Number(trades.value[index - 1].timestamp + '000'),
        ).isSame(Number(x.timestamp + '000'), 'day')
      }

      return {
        ...x,
        isFirstTxOfDay,
      }
    })
})
defineExpose({
  formatTrades,
})
</script>
<style lang="postcss" scoped>
.TxHistoryList {
}
.trade__list {
  margin-bottom: 32px;
  &:last-child {
    margin-bottom: 0;
  }
}
.list__day {
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.25;
}
.list__item {
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
}
.list__empty {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 180px;
  margin-top: 40px;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #ffffff;
  background: #26262b;
  border: 1px solid #4d4d4d;
  border-radius: 16px;
  opacity: 0.5;
}

@media screen and (max-width: 425px) {
  .trade__list {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .list__day {
    margin-bottom: 16px;
    font-size: 16px;
  }
}
</style>
