import { TxType } from '@base/types/transaction'

export const TxTypeText: Record<TxType, { title: string; subtitle: string }> = {
  [TxType.APPROVE]: {
    title: 'Approve',
    subtitle: "You've successfully approved",
  },
  [TxType.DEORDER]: {
    title: 'DeOrder',
    subtitle: "You've successfully DeOrdered",
  },
  [TxType.WITHDRAW]: {
    title: 'Withdraw',
    subtitle: "You've successfully Withdrawn",
  },
  [TxType.HODL_STAKE]: {
    title: 'Stake',
    subtitle: "You've successfully Staked",
  },
  [TxType.SNIPER_STAKE]: {
    title: 'Stake',
    subtitle: "You've successfully Staked",
  },
  [TxType.HODL_UNSTAKE]: {
    title: 'Unstake',
    subtitle: "You've successfully Unstaked",
  },
  [TxType.SNIPER_UNSTAKE]: {
    title: 'Unstake',
    subtitle: "You've successfully Unstaked",
  },
  [TxType.UNWIND]: {
    title: 'Unwind',
    subtitle: "You've successfully Unwinded",
  },
  [TxType.EXERCISE]: {
    title: 'Exercise',
    subtitle: "You've successfully Exercised",
  },
  [TxType.COLLECT]: {
    title: 'Collect',
    subtitle: "You've successfully Collected",
  },
  [TxType.MINT]: {
    title: 'Mint',
    subtitle: "You've successfully Minted",
  },
  [TxType.REDEEM]: {
    title: 'Redeem',
    subtitle: "You've successfully Redeemed",
  },
  [TxType.LOCK]: {
    title: 'Lock',
    subtitle: "You've successfully Locked",
  },
  [TxType.DOB_CLAIM]: {
    title: 'Claim',
    subtitle: "You've successfully Claimed",
  },
  [TxType.CLAIM]: {
    title: 'Claim',
    subtitle: "You've successfully Claimed",
  },
  [TxType.BULLET_CLAIM]: {
    title: 'Bullet Claim',
    subtitle: "You've successfully Claimed Bullets",
  },
  [TxType.BULLET_SELL]: {
    title: 'List to Bazaar',
    subtitle: "You've successfully listed your Bullets",
  },
  [TxType.BULLET_UPDATE]: {
    title: 'Update Bullet Listing',
    subtitle: "You've successfully updated your Bullet listings",
  },
  [TxType.BULLET_CANCEL]: {
    title: 'Off Shelf Bullet Listing',
    subtitle: "You've successfully cancelled your Bullet listings",
  },
  [TxType.BULLET_BUY]: {
    title: 'Buy Bullets',
    subtitle: "You've successfully bought Bullets",
  },
  [TxType.CLAIM_COLLECT]: {
    title: 'Claim & Collect',
    subtitle: "You've successfully Claimed & Collected",
  },
  [TxType.HODL_UNSTAKE_CLAIM]: {
    title: 'Unstake & Claim',
    subtitle: "You've successfully Unstaked & Claimed",
  },
  [TxType.SNIPER_UNSTAKE_CLAIM]: {
    title: 'Unstake & Claim',
    subtitle: "You've successfully Unstaked & Claimed",
  },
}

export const TX_ACTION_BUTTONS = {
  BUY_BULLET: {
    text: 'labels.go-to-buy-bullet',
    url: '/buy',
  },
  MY_BULLET: {
    text: 'labels.go-to-my-bullet',
    url: '/',
  },
  ACCOUNT: {
    text: 'labels.go-to-account',
    url: 'https://app.deorderbook.com/account',
  },
}

export default {}
