import {
  connectWallet,
  allowance,
  approve as chainApprove,
  getContractAddress,
} from 'deorderbook-sdk'
import { initWalletStore, wallet } from 'deorderbook-sdk/store'
import type { Ref } from 'vue'
import type { GetTokensInfoReturnType } from '@base/types/tokens'
import { TxType } from '@base/types/transaction'
import { useWaitTx } from '@base/composables/useWaitTx'

const installWallet = (walletType: string) => {
  initWalletStore({ type: walletType }).install()
}

const initMetaMask = (
  type: string,
  address: Ref<string>,
  isConnected: Ref<boolean>,
  setIsLogged: any,
) => {
  return connectWallet({ type }).then(async (res) => {
    // -1: Plugin not installed, 0: Unsuccessful, 1: Successful
    if (res === 1) {
      const addressFromSDK = await wallet?.getAddress()
      // eslint-disable-next-line no-param-reassign
      address.value = addressFromSDK ?? ''
      // eslint-disable-next-line no-param-reassign
      isConnected.value = true
      setIsLogged(true, type)
    } else if (res === -1) {
      if (type === 'metamask') {
        window.open('https://metamask.io/')
        useNotify({
          type: 'error',
          message: 'After installed MetaMask, Please refresh!',
        })
      } else {
        installWallet(type)
      }
    }
    return res
  })
}

export const useWallet = () => {
  const { setIsLogged } = useAppStore()
  const walletStore = useWalletStore()
  const { isAddress } = useVerifyAddress()
  const route = useRoute()

  const { address, resetWallet, isConnected } = toRefs(walletStore)

  const { showPendingTxModal, hideTxModal, showFailureTxModal, updateTxHash } =
    useTxModalStore()

  const isDebugMode = computed(() => {
    return !!(route?.query?.debug as string)
  })

  const useDebugMode = (type: string) => {
    const debugAddress = route?.query?.debug as string
    if (!isAddress(debugAddress)) {
      return Promise.reject(new Error('Invalid debug address!'))
    }
    address.value = debugAddress
    isConnected.value = true
    setIsLogged(true, type)
    return Promise.resolve(1)
  }

  const connectWallet = (type: string) => {
    if (!useIsClient()) return Promise.resolve(1)
    if (!isConnected.value) {
      if (isDebugMode.value) {
        return useDebugMode(type)
      }
      if (type === 'metamask') {
        return initMetaMask(type, address, isConnected, setIsLogged)
      } else {
        return Promise.reject(new Error(`Unknown wallet type: ${type}`))
      }
    }
    return Promise.resolve(1)
  }
  const disconnect = () => {
    wallet?.disconnect()
    resetWallet.value()
    setIsLogged(false, '')
    // some page that do not have permission should not be displayed.
    window.location.href = '/'
  }

  // [Currency Trading Related]
  const queryAllowance = (
    symbol: string,
    to: string,
    contracts?: 'sniperContracts' | 'bulletContracts' | 'erc20Contracts',
  ) => {
    if (!address || !address.value?.length) return Promise.resolve('0')
    const toAddress =
      to && to?.indexOf('0x') === 0
        ? to
        : getContractAddress(to as GetTokensInfoReturnType)
    if (!toAddress) {
      throw new Error("toAddress can't be undefined")
    }
    return allowance(symbol, address.value, toAddress, contracts).then(
      (amount) => {
        return amount.toString()
      },
    )
  }

  const approve = (
    fromSymbol: string,
    to: string,
    contracts?: 'sniperContracts' | 'bulletContracts' | 'erc20Contracts',
  ) => {
    const toAddress =
      to && to?.indexOf('0x') === 0
        ? to
        : getContractAddress(to as GetTokensInfoReturnType)
    if (!toAddress) {
      throw new Error("toAddress can't be undefined")
    }
    showPendingTxModal({ type: TxType.APPROVE, rows: [] })
    return chainApprove(fromSymbol, toAddress, contracts)
      .then((resp) => {
        const { waitTx } = useWaitTx(resp, 'approve')
        updateTxHash(resp.hash)
        return waitTx().then(() => {
          hideTxModal()
        })
      })
      .catch((err: any) => {
        showFailureTxModal(err)
        return Promise.reject(err)
      })
  }

  return {
    connectWallet,
    disconnectWallet: disconnect,
    installWallet,
    queryAllowance,
    approve,
    ...toRefs(walletStore),
    isDebugMode,
  }
}
