export interface NavLink {
  path: string
  name: string
  active: boolean
  activeClass: string
  locale: string
  target?: string
  isExternal?: boolean
  isSoon?: boolean
}

export const BULLET_NAV_LINKS: NavLink[] = [
  {
    path: '/bullet/bullet-market',
    name: 'bulletMarket',
    active: false,
    activeClass: 'active',
    locale: 'header.bulletMarket',
  },
  {
    path: '/bullet/my-bullet',
    name: 'myBullet',
    active: false,
    activeClass: 'active',
    locale: 'header.myBullet',
  },
]

export const NAV_LINKS: NavLink[] = [
  {
    path: '/',
    name: 'DeOrder',
    active: false,
    activeClass: 'active',
    locale: 'header.deorder',
  },
  {
    path: '/account',
    name: 'Account',
    active: false,
    activeClass: 'active',
    locale: 'header.account',
  },
  {
    path: '/lock',
    name: 'Lock',
    active: false,
    activeClass: 'active',
    locale: 'header.lock',
  },
  {
    path: '/claim',
    name: 'Claim & Exercise',
    active: false,
    activeClass: 'active',
    locale: 'header.exercise',
  },
  // {
  //   path: '/leaderboard',
  //   name: 'Leaderboard',
  //   active: false,
  //   activeClass: 'active',
  //   locale: 'header.leaderboard',
  // },
]

export const EXTERNAL_NAV_LINKS: NavLink[] = [
  {
    path: 'https://nft.deorderbook.com',
    name: 'NFT',
    active: false,
    activeClass: 'no-active',
    locale: 'header.nft',
    target: '_blank',
    isExternal: true,
  },
  {
    path: 'https://explorer.deorderbook.com',
    name: 'Explorer',
    active: false,
    activeClass: 'no-active',
    locale: 'header.explorer',
    target: '_blank',
    isExternal: true,
  },
  {
    path: 'https://docs.deorderbook.com',
    name: 'Docs',
    active: false,
    activeClass: 'no-active',
    locale: 'header.docs',
    target: '_blank',
    isExternal: true,
  },
  {
    path: 'https://bazaar.deorderbook.com',
    name: 'Bazaar',
    active: false,
    activeClass: 'no-active',
    locale: 'header.bazaar',
    target: '_blank',
    isExternal: true,
  },
  {
    path: 'https://stats.deorderbook.com',
    name: 'Stats',
    active: false,
    activeClass: 'no-active',
    locale: 'header.stats',
    target: '_blank',
    isExternal: true,
  },
]

export const UPDATE_DURATION = 15 * 1000 // 15 seconds
export const BLOCK_PER_MINUTE = 5
export const BLOCK_PER_DAY = 24 * 60 * BLOCK_PER_MINUTE
export const BLOCK_PER_YEAR = 365 * BLOCK_PER_DAY
