/**
 * Obtain user address information, including judgment on its identity. If you only need to get the current user's address, this function can be used instead of useWallet
 */
export default function useAccount() {
  const { address, accounts } = toRefs(useWalletStore())

  // Has permission to call the distributions and optionFactory contracts,different contracts have different owners
  const isOwner = computed(() => {
    return address.value === accounts.value.owner.toLowerCase()
  })
  const isFeeCollector = computed(() => {
    return address.value === accounts.value.feeCollector.toLowerCase()
  })
  const isBULLETCollector = computed(() => {
    return address.value === accounts.value.bulletCollector.toLowerCase()
  })
  const isOTCOwner = computed(() => {
    return address.value === accounts.value.otcOwner.toLowerCase()
  })
  const isWorker = computed(() => {
    return address.value === accounts.value.worker.toLowerCase()
  })
  const isRewardDispatcher = computed(() => {
    return address.value === accounts.value.rewardDispatcher.toLowerCase()
  })

  return {
    address,
    isOwner,
    isFeeCollector,
    isBULLETCollector,
    isOTCOwner,
    isWorker,
    isRewardDispatcher,
    accounts,
  }
}
