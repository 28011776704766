import { ERROR_CODE } from './constants'

export const isTxRejectedError = (error: any) => {
  return error && error?.code === ERROR_CODE.ACTION_REJECTED
}

export const logTxError = (error: any) => {
  if (!error || isTxRejectedError(error)) {
    return
  }
  console.error(error)
}
