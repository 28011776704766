import walletConnect from '@base/assets/img/wallets/walletconnet.png'
import binance from '@base/assets/img/wallets/binance.png'
import metaMask from '@base/assets/img/wallets/metamask.png'
export interface NavLink {
  path: string
  name: string
  active: boolean
  activeClass: string
  locale: string
  target?: string
  isExternal?: boolean
  isSoon?: boolean
}

export enum DOBApplets {
  APP = 'app',
  NFT = 'nft',
  BAZAAR = 'bazaar',
  EXPLORER = 'explorer',
  PRO = 'pro',
}

const APP_NAV_LINKS: NavLink[] = [
  {
    path: '/',
    name: 'DeOrder',
    active: false,
    activeClass: 'active',
    locale: 'header.deorder',
  },
  {
    path: '/account',
    name: 'Account',
    active: false,
    activeClass: 'active',
    locale: 'header.account',
  },
  {
    path: '/lock',
    name: 'Lock',
    active: false,
    activeClass: 'active',
    locale: 'header.lock',
  },
  {
    path: '/claim',
    name: 'Claim & Exercise',
    active: false,
    activeClass: 'active',
    locale: 'header.exercise',
  },
  {
    path: '/leaderboard',
    name: 'Leaderboard',
    active: false,
    activeClass: 'active',
    locale: 'header.leaderboard',
  },
]

const APP_EXTERNAL_NAV_LINKS: NavLink[] = [
  {
    path: 'https://nft.deorderbook.com',
    name: 'NFT',
    active: false,
    activeClass: 'no-active',
    locale: 'header.nft',
    target: '_blank',
    isExternal: true,
  },
  {
    path: 'https://explorer.deorderbook.com',
    name: 'Explorer',
    active: false,
    activeClass: 'no-active',
    locale: 'header.explorer',
    target: '_blank',
    isExternal: true,
  },
  {
    path: 'https://docs.deorderbook.com',
    name: 'Docs',
    active: false,
    activeClass: 'no-active',
    locale: 'header.docs',
    target: '_blank',
    isExternal: true,
  },
  {
    path: 'https://bazaar.deorderbook.com',
    name: 'Bazaar',
    active: false,
    activeClass: 'no-active',
    locale: 'header.bazaar',
    target: '_blank',
    isExternal: true,
    isSoon: true,
  },
]

const BAZAAR_NAV_LINKS: NavLink[] = [
  {
    path: '/bullet/bullet-market',
    name: 'bulletMarket',
    active: false,
    activeClass: 'active',
    locale: 'header.bulletMarket',
  },
  {
    path: '/bullet/my-bullet',
    name: 'myBullet',
    active: false,
    activeClass: 'active',
    locale: 'header.myBullet',
  },
]

const NFT_NAV_LINKS: NavLink[] = []
const EXPLORER_NAV_LINKS: NavLink[] = []
const PRO_NAV_LINKS: NavLink[] = [
  {
    path: '/',
    name: 'Options',
    active: false,
    activeClass: 'active',
    locale: 'header.options',
  },
  {
    path: '/approve',
    name: 'Approve',
    active: false,
    activeClass: 'active',
    locale: 'header.approve',
  },
  {
    path: '/settings',
    name: 'Settings',
    active: false,
    activeClass: 'active',
    locale: 'header.settings',
  },
]

export const NAV_LINKS: {
  [key in DOBApplets]: { main: NavLink[]; external?: NavLink[] }
} = {
  app: {
    main: APP_NAV_LINKS,
    external: APP_EXTERNAL_NAV_LINKS,
  },
  bazaar: { main: BAZAAR_NAV_LINKS },
  nft: { main: NFT_NAV_LINKS },
  explorer: { main: EXPLORER_NAV_LINKS },
  pro: { main: PRO_NAV_LINKS },
}

export const WALLETS = reactive([
  { label: 'MetaMask', icon: metaMask, id: 'metamask' },
  { label: 'Wallet Connect', icon: walletConnect, id: 'walletconnect' },
  { label: 'Binance Chain Wallet', icon: binance, id: 'binanceWallet' },
])

export const UPDATE_DURATION = 15 * 1000 // 15 seconds
export const BLOCK_PER_MINUTE = 5
export const BLOCK_PER_DAY = 24 * 60 * BLOCK_PER_MINUTE
export const BLOCK_PER_YEAR = 365 * BLOCK_PER_DAY

export const ERROR_CODE = {
  REQUEST_PENDING: -32002,
  ACTION_REJECTED: 4001,
} as const
