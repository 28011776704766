<template>
  <el-dialog
    ref="elRef"
    v-bind="{ ...$attrs, ...props }"
    destroy-on-close
    class="YDialog"
  >
    <template v-if="$slots.header" #header>
      <slot name="header"></slot>
    </template>
    <template #default>
      <slot name="default"></slot>
    </template>
    <template v-if="$slots.footer" #footer>
      <slot name="footer"></slot>
    </template>
  </el-dialog>
</template>
<script setup lang="ts">
import { dialogProps } from 'element-plus'

// The parent component calls the element-ui component method through ref.value.elRef.xxx
const elRef = ref(null)
const props = defineProps({
  ...dialogProps,
  /** Whether the dialog is centered, the default is true */
  alignCenter: {
    type: dialogProps.alignCenter,
    default: true,
  },
  /** Whether the content is centered, the default is true */
  center: {
    type: Boolean,
    default: true,
  },
  width: {
    type: dialogProps.width.type,
    default: '418px',
  },
})

// defineEmits(buttonEmits) Do not add defineEmits, it will block the child component from triggering events.
</script>
<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>
<style lang="postcss">
.YDialog.el-dialog {
  padding-bottom: 16px;
  color: var(--color);
  background: radial-gradient(
    49.67% 49.67% at 88.76% 5.88%,
    #323232 0%,
    #26262b 95.31%
  );
  border: 0.5px solid #4d4d4d;
  border-radius: 16px;
  /* stylelint-disable-next-line order/properties-order */
  max-height: calc(100vh - 32px);
  overflow-y: auto;

  & .el-dialog__header {
    padding: 0;
    margin-top: 24px;
    margin-right: 0;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
  }
  & .el-dialog__headerbtn {
    top: 12px;
    right: 12px;
    width: 24px;
    height: 24px;
    font-size: 24px;
  }
  & .el-dialog__body {
    padding: 24px 24px 0;
  }
  & .el-dialog__footer {
    padding-bottom: 0;
    margin-top: 24px;
  }
}

@media screen and (max-width: 768px) {
  .YDialog.el-dialog {
    width: calc(100% - 32px);
    margin: auto 16px;
    & .el-dialog__header {
      margin-top: 16px;
    }
    & .el-dialog__headerbtn {
      top: 8px;
      right: 8px;
      width: 16px;
      height: 16px;
      font-size: 16px;
    }
    & .el-dialog__body {
      padding: 16px 16px 0;
    }
    & .el-dialog__footer {
      margin-top: 16px;
    }
  }
}
</style>
