import type {
  Symbols,
  AllTokenType as AllTokenTypeFromSDK,
} from 'deorderbook-sdk/types'
import { getTokenBySymbol, getTokens } from 'deorderbook-sdk'
import { Big } from 'big.js'
import IconEHODL from '@base/assets/img/icon_coin_eHODL@2x.png'
import IconUSDC from '@base/assets/img/tokens/USDC.png'
import IconWBTC from '@base/assets/img/tokens/WBTC.png'
import IconUHODL from '@base/assets/img/tokens/uHODL.png'
import IconBHODL from '@base/assets/img/tokens/bHODL.png'
import IconDOB from '@base/assets/img/tokens/DOB.png'
import IconBBULLET from '@base/assets/img/tokens/bBULLET.png'
import IconEBULLET from '@base/assets/img/tokens/eBULLET@2x.png'
import IconUBULLET from '@base/assets/img/tokens/uBULLET.png'
import IconBSNIPER from '@base/assets/img/tokens/bSNIPER.png'
import IconESNIPER from '@base/assets/img/tokens/eSNIPER@2x.png'
import IconUSNIPER from '@base/assets/img/tokens/uSNIPER.png'
import IconETH from '@base/assets/img/tokens/ETH.png'
import IconWETH from '@base/assets/img/tokens/WETH@2x.png'
import useChains from '@base/composables/useChains'

import {
  BaseToken,
  type BasicTokenType,
  type AllTokenType,
} from '@base/types/tokens'

/** Parameters for bullet price API */
interface BulletPriceParams {
  /** Exercise time, in seconds */
  exerciseTimestamp: string
  /** Exercise price, needs to be divided by precision */
  strikePrice: string
}
/** Parameters for Sniper price API */
interface SniperPriceParams {
  /** Exercise price, needs to be divided by precision */
  strikePrice: string
  /** sniper address */
  address: string
}
interface GetTokenByOptions {
  /** token address */
  address?: string
}
const getTokenEnums = () => {
  const sellTokenHODL = getTokenBySymbol('sellTokenHODL')
  return {
    USDC: {
      ...getTokenBySymbol('USDC'),
      icon: IconUSDC,
      pairToken: getTokenBySymbol('uHODL').symbol,
    },
    WETH: {
      ...getTokenBySymbol('WETH'),
      icon: IconWETH,
      pairToken: 'ETH',
    },
    ETH: {
      ...getTokenBySymbol('ETH'),
      icon: IconETH,
      pairToken: 'WETH',
    },
    sellToken: {
      ...getTokenBySymbol('sellToken'),
      icon: sellTokenHODL.originalSymbol === 'bHODL' ? IconWBTC : IconWETH,
      pairToken: sellTokenHODL.symbol,
    },
    uHODL: {
      ...getTokenBySymbol('uHODL'),
      icon: IconUHODL,
      pairToken: getTokenBySymbol('USDC').symbol,
    },
    sellTokenHODL: {
      ...sellTokenHODL,
      icon: sellTokenHODL.originalSymbol === 'bHODL' ? IconBHODL : IconEHODL,
      pairToken: 'sellToken',
    },
    DOB: {
      ...getTokenBySymbol('DOB'),
      icon: IconDOB,
    },
    uBULLET: {
      symbol: 'uBULLET',
      icon: IconUBULLET,
    },
    sellTokenBULLET: {
      symbol: 'sellTokenBULLET',
      icon:
        sellTokenHODL.originalSymbol === 'bHODL' ? IconBBULLET : IconEBULLET,
    },
    uSNIPER: {
      symbol: 'uSNIPER',
      icon: IconUSNIPER,
      USDExchangeRate: async (params: Omit<SniperPriceParams, 'address'>) => {
        const { strikePrice } = params
        const sellTokenPrice =
          await getTokenBySymbol('sellToken').USDExchangeRate()
        return sellTokenPrice > Number(strikePrice)
          ? '1'
          : Big(sellTokenPrice).div(strikePrice).toFixed()
      },
    },
    sellTokenSNIPER: {
      symbol: 'sellTokenSNIPER',
      icon:
        sellTokenHODL.originalSymbol === 'bHODL' ? IconBSNIPER : IconESNIPER,
      USDExchangeRate: async (params: Omit<SniperPriceParams, 'address'>) => {
        const { strikePrice } = params
        const sellTokenPrice =
          await getTokenBySymbol('sellToken').USDExchangeRate()
        return sellTokenPrice > Number(strikePrice)
          ? strikePrice
          : String(sellTokenPrice)
      },
    },
  } as const
}

export type TokenSymbols = keyof ReturnType<typeof getTokenEnums>
const getTokenPair = (
  symbol: Exclude<
    TokenSymbols,
    | 'DOB'
    | 'uBULLET'
    | 'sellTokenBULLET'
    | 'uSNIPER'
    | 'sellTokenSNIPER'
    | 'WETH'
  >,
) => {
  // @ts-ignore
  return getTokenEnums()[symbol]?.pairToken
}
const getTokenBuyOrSellPair = (
  symbol: 'uHODL' | 'sellTokenHODL' | 'sellToken' | 'USDC' | 'ETH' | 'WETH',
) => {
  const tokenEnums = {
    uHODL: 'sellToken',
    sellTokenHODL: 'USDC',
    sellToken: 'uHODL',
    USDC: 'sellTokenHODL',
    ETH: 'WETH',
    WETH: 'ETH',
  }
  return tokenEnums[symbol]
}
const getTokenIcon = (symbol: TokenSymbols) => {
  return getTokenEnums()[symbol].icon
}
/**
 * @description get token by x, for now,only support address
 * @param {GetTokenByOptions} options
 * @notice get address only support `BasicTokenType`
 * @return {*}
 */
const getTokenBy = (options: GetTokenByOptions) => {
  if (options.address !== undefined) {
    return Object.values(getTokenEnums()).find(
      (x) =>
        'address' in x &&
        x.address?.toLowerCase() === options.address?.toLowerCase(),
    )
  }
}

const getTokenAddress = (symbol: BasicTokenType) => {
  return getTokenEnums()[symbol].address!
}
const getOriginalSymbol = (symbol: AllTokenTypeFromSDK) => {
  const token = getTokenBySymbol(symbol)
  if (!token) {
    console.warn('Non-token strings should not be passed in')
  }
  return token?.originalSymbol ?? symbol
}
const checkTokenExist = (symbol: string) => {
  const tokenSymbol = getTokenBySymbol(symbol as AllTokenTypeFromSDK)
  return Boolean(tokenSymbol)
}
export default () => {
  /**
   * @description Mint or redeem token pair
   * @param {string} symbol
   * @return {*}
   */

  const getTokenBalance = (
    symbol:
      | 'USDC'
      | 'sellToken'
      | 'uHODL'
      | 'sellTokenHODL'
      | 'DOB'
      | 'ETH'
      | 'WETH',
  ) => {
    const { tokenBalance } = toRefs(useWalletStore())

    return computed(() => {
      const balanceEnums = {
        USDC: tokenBalance.value.balanceUSDC,
        sellToken: tokenBalance.value.balanceSellToken,
        uHODL: tokenBalance.value.balanceUHODL,
        sellTokenHODL: tokenBalance.value.balanceSellTokenHODL,
        DOB: tokenBalance.value.balanceDOB,
        ETH: tokenBalance.value.balanceETH,
        WETH: tokenBalance.value.balanceWETH,
      }
      return balanceEnums[symbol]
    })
  }

  /** Get the dollar price of the token */
  const getTokenPrice = (
    symbol: TokenSymbols,
    params?: BulletPriceParams | SniperPriceParams,
  ) => {
    // NOTE: Do not use useTokensStore directly in the top-level area, as it will cause a circular call issue.
    const store = useTokensStore()
    const {
      getSniperPrice,
      tokenDOB,
      tokenSellToken,
      tokenWETH,
      tokenETH,
      tokenUHODL,
      tokenUSDC,
      tokenSellTokenHODL,
    } = toRefs(store)

    const tokenEnums = {
      DOB: tokenDOB,
      sellToken: tokenSellToken,
      WETH: tokenWETH,
      ETH: tokenETH,
      USDC: tokenUSDC,
      uHODL: tokenUHODL,
      sellTokenHODL: tokenSellTokenHODL,
    } as const
    if (symbol === 'uBULLET' || symbol === 'sellTokenBULLET') {
      const _params = params as BulletPriceParams

      return computedAsync(async () => {
        return await store.getBulletPrice({
          type: symbol,
          strikePrice: _params.strikePrice,
          exerciseTimestamp: _params.exerciseTimestamp,
        })
      }, '0')
    } else if (symbol === 'uSNIPER' || symbol === 'sellTokenSNIPER') {
      const _params = params as SniperPriceParams
      return computedAsync(async () => {
        const value = await getSniperPrice.value(
          symbol,
          _params.strikePrice,
          _params.address,
        )
        return value.value?.priceUSD !== undefined ? value.value?.priceUSD : '0'
      }, '0')
    }
    return computed(() => tokenEnums[symbol].value.priceUSD)
  }

  // [ Token Decimals ]
  const { currentChain } = useChains()
  /**
   * @description get token decimals by token symbol
   * @param {MaybeRefOrGetter<TokenInfo['symbol']>} token
   */
  const getTokenDecimals = (token: MaybeRefOrGetter<AllTokenType>) => {
    if (token === BaseToken.ETH) {
      return computed(() => currentChain.value.nativeCurrency.decimals)
    } else {
      const tokenList = getTokens(currentChain.value?.chainId)

      return computed(() => {
        if (toValue(token) === undefined || toValue(token) === null)
          return undefined

        let tokenForDecimals = toValue(token) as Symbols

        const lowerCaseToken = tokenForDecimals.toLowerCase()
        if (lowerCaseToken.includes('sniper')) {
          tokenForDecimals = 'sniper'
        } else if (lowerCaseToken.includes('bullet')) {
          tokenForDecimals = 'bullet'
        }

        return tokenList.find((tokenInfo) =>
          tokenInfo.symbol
            .toLowerCase()
            .includes(tokenForDecimals.toLowerCase()),
        )?.decimals as number
      })
    }
  }

  /**
   * Divide the amount by the token decimals
   * @note When used with OTCPrice, OTCBuyToken should be passed to token
   * @param {(MaybeRefOrGetter<string | number | Big>)} amount - The amount to be divided
   * @param {MaybeRefOrGetter<TokenInfo['symbol']>} token - The token symbol
   * @returns {ComputedRef<string>} - The computed divided result
   */
  const divDecimals = (
    amount: MaybeRefOrGetter<string | number | Big>,
    token: MaybeRefOrGetter<AllTokenType>,
  ) => {
    return computed(() => {
      return Big(toValue(amount) || 0)
        .div(Big(10).pow(getTokenDecimals(token).value || 0))
        .toFixed()
    })
  }
  /**
   * Multiply the amount by the token decimals
   * @note When used with OTCPrice, OTCBuyToken should be passed to token
   * @param {(MaybeRefOrGetter<string | number | Big>)} amount - The amount to be multiplied
   * @param {MaybeRefOrGetter<TokenInfo['symbol'] | Token | Sniper | Bullet>} token - The token symbol
   * @returns {ComputedRef<string>} - The computed multiplied result
   */
  const mulDecimals = (
    amount: MaybeRefOrGetter<string | number | Big>,
    token: MaybeRefOrGetter<AllTokenType>,
  ) => {
    return computed(() => {
      return Big(toValue(amount) || 0)
        .times(Big(10).pow(getTokenDecimals(token).value || 0))
        .toFixed()
    })
  }

  return {
    getTokenEnums,
    getTokenPair,
    getTokenBuyOrSellPair,
    getTokenIcon,
    getTokenBalance,
    getTokenPrice,
    getTokenAddress,
    getTokenBy,
    getTokenDecimals,
    getOriginalSymbol,
    divDecimals,
    mulDecimals,
    checkTokenExist,
  }
}
