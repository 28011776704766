import { storeToRefs } from 'pinia'

export interface WalletReadyOptions {
  /**
   * Timing of call, setup: wallet initialization, contract methods that do not require account information can be called. connected: execute after connecting account wallet.
   */
  status: 'setup' | 'connected'
}

/**
 * @description Hook function, ensure that contract methods can be called, default to execute after connecting the account, the timing of the call can be modified through options
 * @param {() => void} cb Callback function
 * @param {*} [options={} as WalletReadyOptions]
 */
export function onWalletReady(
  cb: () => void,
  options = {} as WalletReadyOptions,
) {
  const { status = 'connected' } = options
  const { isSetOption, isConnected } = storeToRefs(useWalletStore())
  let watchValue: typeof isSetOption | typeof isConnected
  if (status === 'connected') {
    watchValue = isConnected
  } else if (status === 'setup') {
    watchValue = isSetOption
  }
  const isMounted = useMounted()

  watch(
    [isMounted, watchValue!],
    () => {
      if (isMounted.value && watchValue.value) {
        cb?.()
      }
    },
    {
      immediate: true,
    },
  )
}
