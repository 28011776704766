<template>
  <div class="tx-flow-info">
    <h5 class="tx-time">
      {{ txTime }}
    </h5>
    <div class="tx-flow-data">
      <FlowCard
        v-if="flowOutData.length !== 0"
        title="Amount OUT"
        :data="flowOutData"
      />
      <SVGArrowDown
        v-if="flowOutData.length !== 0 && flowInData.length !== 0"
        class="arrow-down"
      />
      <FlowCard
        v-if="flowInData.length !== 0"
        title="Amount IN"
        :data="flowInData"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import dayjs from 'dayjs'
import { type PropType } from 'vue'
import { type FlowData, FLOW_LABELS } from './utils'
import FlowCard from './FlowCard.vue'
import { type TokenSymbols } from '@base/composables/useTokens'
import { type FormatTrade } from '@base/types/trade'
import SVGArrowDown from '~icons/lucide/arrow-down'

const props = defineProps({
  data: {
    type: Object as PropType<FormatTrade>,
    default: () => ({}),
  },
})
const { data } = toRefs(props)
const { getTokenIcon, divDecimals, getOriginalSymbol } = useTokens()
const transaction = computed<FormatTrade>(() => data.value)

const txTime = computed(() => {
  return dayjs(Number(transaction.value.timestamp + '000')).format('hh:mm A')
})

// [ Flow Data]

const flowOutData = computed(() => {
  return transaction.value.outTokens.map((outToken) => {
    const token = outToken.token as TokenSymbols
    return {
      label:
        FLOW_LABELS.OUT[
          transaction.value.label as keyof typeof FLOW_LABELS.OUT
        ] || '',
      token,
      amount:
        useTokenNumberFormat(divDecimals(outToken.amount ?? 0, token).value, {
          token,
        }) +
        ' ' +
        getOriginalSymbol(token),
      usd: useUSDFormat(outToken.usd ?? 0, { showApprox: true }),
      icon: getTokenIcon(token),
    }
  }) as FlowData[]
})

const flowInData = computed(() => {
  const inTokens: FlowData[] = []
  transaction.value.inTokens.forEach((inToken, index) => {
    const token = inToken.token as TokenSymbols
    inTokens.push({
      label:
        index === 0
          ? FLOW_LABELS.IN[
              transaction.value.label as keyof typeof FLOW_LABELS.IN
            ] || ''
          : token === 'DOB'
            ? FLOW_LABELS.IN.Claim
            : '',
      token,
      amount:
        useTokenNumberFormat(divDecimals(inToken?.amount || '0', token).value, {
          token,
        }) +
        ' ' +
        getOriginalSymbol(token),
      usd: useUSDFormat(inToken?.usd ?? '0', { showApprox: true }),
      icon: getTokenIcon(token),
    })
  })
  // if (
  //   transaction.value.underToken &&
  //   transaction.value.underTokenAmount &&
  //   transaction.value.underTokenUSD
  // ) {
  //   const token = transaction.value.underToken
  //   inTokens.push({
  //     label: 'labels.deordered',
  //     token: transaction.value.underToken,
  //     amount:
  //       useTokenNumberFormat(divDecimals(transaction.value.underTokenAmount, token).value, {
  //         token,
  //       }) +
  //       ' ' +
  //       token,
  //     usd: useUSDFormat(transaction.value.underTokenUSD, { showApprox: true }),
  //     icon: getTokenIcon(token),
  //   })
  // }
  return inTokens
})
</script>
<style lang="postcss" scoped>
.tx-flow-info {
}
.tx-flow-data {
  margin-top: 2px;
}
.tx-time {
  height: var(--row1-height);
  font-size: 16px;
  font-weight: 400;
  line-height: var(--row1-height);
}
.arrow-down {
  display: block;
  width: 24px;
  height: 24px;
  margin: 4px auto 0;
  opacity: 0.3;
}
@media screen and (max-width: 425px) {
  .tx-time {
    font-size: 14px;
  }
}
</style>
