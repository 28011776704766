<template>
  <el-config-provider :locale="locales">
    <NuxtLayout is-layout>
      <div class="app">
        <NuxtPage />
      </div>
    </NuxtLayout>
  </el-config-provider>
</template>
<script setup lang="ts">
// A div wrapper around NuxtPage is used to fix the issue where it can't navigate after packaging, https://github.com/nuxt/framework/issues/2985
import { Big } from 'big.js'
import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/ru'
import { en, ru } from 'element-plus/es/locale/index'
import META_TAGS from '~/utils/meta-tags'
Big.RM = 0
Big.PE = 50

const { setupApp } = useApp()
// should always be executed before calling store and sdk
await setupApp()
// NOTE: Use before i18n
overrideI18nTranslate()

const { locale } = useCustomI18n()

useSetPageMeta(META_TAGS, {
  customTitle: (title) => `DeOrderBook | ${title}`,
})

const route = useRoute()
const currentLocale =
  (route?.matched[0]?.name as string)?.split('___')[1] || 'en'
locale.value = currentLocale

const locales = computed(() => {
  if (locale.value === 'en') return en
  else if (locale.value === 'ru') return ru
  return en
})

dayjs.locale(locale.value)
</script>
<style lang="postcss" scoped>
.app {
  display: flex;
  flex: 1;
  flex-direction: column;
}
</style>
