<template>
  <y-dialog
    :model-value="modelValue"
    width="max-content"
    @update:model-value="updateModelValue"
  >
    <div class="tx-history-dialog">
      <TxHistoryHeader @logout="onLogout" />
      <h4 class="activity-count">
        {{ $t('ui.tx-history.recent-activity') }} ({{ activityCount }})
      </h4>
      <TxHistoryList ref="txHistoryListRef" />
    </div>
  </y-dialog>
</template>
<script setup lang="ts">
import TxHistoryHeader from './TxHistoryHeader.vue'
import TxHistoryList from './TxHistoryList.vue'
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
})
const emits = defineEmits(['update:model-value'])
const updateModelValue = (e: boolean) => {
  emits('update:model-value', e)
}
const onLogout = () => {
  updateModelValue(false)
}
const txHistoryListRef = ref<InstanceType<typeof TxHistoryList> | null>(null)
const activityCount = computed(() => {
  return txHistoryListRef?.value?.formatTrades.length
})
</script>
<style lang="postcss" scoped>
.tx-history-dialog {
  width: 800px;
  height: 100%;
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
}
.activity-count {
  margin: 40px 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.25;
}
@media screen and (max-width: 1024px) {
  .tx-history-dialog {
    width: 100%;
  }
  .activity-count {
    margin: 24px 0;
    font-size: 20px;
  }
}
@media screen and (max-width: 425px) {
  .tx-history-dialog {
    max-height: calc(100vh - 32px);
  }
  .activity-count {
    margin: 16px 0;
    font-size: 18px;
  }
}
</style>
