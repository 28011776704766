<template>
  <div class="tx-amount-block">
    <SVGPlus v-if="showPlusIcon" class="svg__plus"></SVGPlus>
    <!-- <p v-if="data.label !== ''" class="card__action">
      {{ $t(data.label) }}
    </p> -->
    <div class="card__token">
      <img :src="data.icon" alt="" />
      <p>
        {{ data.amount }}
      </p>
    </div>
    <p class="card__usd">
      {{ data.usd }}
    </p>
  </div>
</template>
<script setup lang="ts">
import { type PropType } from 'vue'
import { type FlowData } from './utils'
import SVGPlus from '~icons/lucide/plus'

const props = defineProps({
  data: {
    type: Object as PropType<FlowData>,
    default: () => ({}),
  },
  showPlusIcon: {
    type: Boolean,
    default: false,
  },
})
const { data, showPlusIcon } = toRefs(props)
</script>
<style lang="postcss" scoped>
.tx-amount-block {
}
.card__action {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.5;
}
.card__token {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  & img {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}
.card__usd {
  margin-top: 4px;
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.5;
}
.svg__plus {
  display: block;
  width: 24px;
  height: 24px;
  margin: 4px auto 0;
  opacity: 0.3;
}
@media screen and (max-width: 425px) {
  .card__token {
    font-size: 14px;
    line-height: 18px;
    & img {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }
}
</style>
