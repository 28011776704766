import { ElNotification } from 'element-plus'
import type {
  NotificationParams,
  NotificationOptions,
} from 'element-plus/es/components/notification/src/notification'

export type NotifyType = 'success' | 'error' | 'tx-success' | 'tx-error'

export type UseNotifyOptions = Omit<
  Extract<NotificationParams, Partial<NotificationOptions>>,
  'type'
> & {
  type?: NotifyType
}

const msgEnums = {
  success: 'Operation Successful',
  error: 'Operation Failed',
  'tx-success': 'Transaction Confirmed',
  'tx-error': 'Transaction Failed',
} as const

/**
 * @description Global message notification, default type is 'tx-success'
 */
export function useNotify(options?: UseNotifyOptions) {
  const type = options?.type || 'tx-success'
  const { type: _, ...otherOptions } = options || {}
  let elNotifyType: 'success' | 'error'

  if (options?.type === undefined) {
    elNotifyType = 'success'
  } else if (['success', 'tx-success'].includes(options.type)) {
    elNotifyType = 'success'
  } else if (['error', 'tx-error'].includes(options.type)) {
    elNotifyType = 'error'
  } else {
    return
  }

  const newOptions = {
    title: '',
    message: msgEnums[type],
    offset: 100,
    ...otherOptions,
  }

  const customClass = 'y-notify' + ' is-' + elNotifyType

  ElNotification({
    ...newOptions,
    type: elNotifyType,
    customClass,
  })
}
