import { getDefaultParams } from '@base/composables/useCustomI18n'
/**
 * `$t` with default parameters.
 */
export function overrideI18nTranslate() {
  const nuxtApp = useNuxtApp()
  const originalTranslate = nuxtApp.vueApp.config.globalProperties.$t
  // @ts-ignore
  nuxtApp.vueApp.config.globalProperties.$t = function (
    key: string,
    params: {},
  ): string {
    const defaultParams = getDefaultParams()
    // Merge the passed parameters with the default parameters
    const finalParams: Record<string, any> = { ...defaultParams, ...params }

    // Call the original $t method
    // @ts-ignore
    return originalTranslate.call(this, key, finalParams)
  }
}
