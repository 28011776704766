import { Big } from 'big.js'
import type { Ref } from 'vue'
/**
 * If the formatting object is a dollar token (USDC and uHODL) or DOB, or if it is displayed in dollars, maxDigitsWithZero should be specified as 2
 */
export const LOW_PRECISION_TOKEN = ['USDC', 'uHODL', 'DOB']
const DEFAULT_DEFAULT_MAX_LENGTH = 12
const DEFAULT_DEFAULT_MAX_DIGITS = 4
export const DEFAULT_MAX_DIGITS_WITH_ZERO = 8
export interface LimitNumberOptions {
  maxLength?: number
  maxDigits?: number
  /** The default value is 8. If the formatting object is a dollar token (USDC and uHODL) or DOB, or if it is displayed in dollars, maxDigitsWithZero should be specified as 2 */
  maxDigitsWithZero?: number
}
/**
 * Calculate the length of zero after the decimal point
 */
const calcDigitsZeroLength = (value: `${number}`) => {
  const digits = value.split('.')[1] || ''
  const digitsWithoutZeroLength = String(Number(digits)).length
  const zeroLength = digits.length - digitsWithoutZeroLength
  return zeroLength
}
/**
 * Limit the length of the numeric string, with the non-zero part after the decimal point keeping up to 4 significant digits, and the entire string having a maximum length of 12 digits. The parameters can be responsive types.
 * @param {(Ref<string> | string)} num Input numeric string
 * @param {LimitNumberOptions} [options={ maxLength:12, maxDigits:4, maxDigitsWithZero: 8 }]
 * @notice If it's for input component use, it's recommended to use useNumberLimitDebounceFn instead
 */
export function useNumberLimit(
  num: Ref<`${number}`> | `${number}` | string,
  options?: LimitNumberOptions,
) {
  const _options = Object.assign(
    {
      maxLength: DEFAULT_DEFAULT_MAX_LENGTH,
      maxDigits:
        options &&
        typeof options.maxDigitsWithZero === 'number' &&
        options.maxDigitsWithZero < DEFAULT_DEFAULT_MAX_DIGITS
          ? options?.maxDigitsWithZero
          : DEFAULT_DEFAULT_MAX_DIGITS,
      maxDigitsWithZero: DEFAULT_MAX_DIGITS_WITH_ZERO,
    },
    options,
  )

  const { maxLength, maxDigits, maxDigitsWithZero } = toRefs(reactive(_options))

  const value = ref('0' as `${number}`)

  const formatValue = (
    num: Ref<`${number}` | string> | `${number}` | string,
  ) => {
    const numString = Big(unref(num || '0'))
      .round(unref(maxDigitsWithZero))
      .toFixed() as `${number}`
    const zeroLength = calcDigitsZeroLength(numString)
    // Keep the length of decimals based on the number of zeros after the decimal point
    const limitDigitsValue = Big(numString)
      .round(zeroLength + unref(maxDigits))
      .prec(unref(maxLength)) // Limit the string to a maximum of 12 digits
      .toFixed()

    return limitDigitsValue.substring(
      0,
      numString.includes('.') ? unref(maxLength) + 1 : unref(maxLength),
    ) as `${number}`
  }

  watch(
    [() => num, maxDigitsWithZero, maxDigits, maxLength],
    () => {
      value.value = formatValue(num)
    },
    {
      immediate: true,
    },
  )

  return value
}
