<template>
  <NuxtLink
    :to="isFullUrl ? url : `https://explorer.deorderbook.com${url}`"
    target="_blank"
    rel="noreferrer"
    class="explorer-link"
    :class="className"
  >
    <p>{{ text }}</p>
    <SVGArrowOutward
      width="16"
      height="16"
      class="link-arrow"
    ></SVGArrowOutward>
  </NuxtLink>
</template>
<script setup lang="ts">
import SVGArrowOutward from '~icons/ic/baseline-arrow-outward'

const props = defineProps({
  url: {
    type: String,
    required: true,
  },
  text: {
    type: String,
    default: 'See in Explorer',
  },
  className: {
    type: String,
    default: '',
  },
  isFullUrl: {
    type: Boolean,
    default: false,
  },
})

// logout
const { url, text, className, isFullUrl } = toRefs(props)
</script>
<style lang="postcss" scoped>
.explorer-link {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 18px;
  text-decoration: underline;
  opacity: 0.6;
  transition: all 200ms ease-in-out;
  &:hover {
    opacity: 1;
  }
}
.link-arrow {
  margin-left: 4px;
}
</style>
