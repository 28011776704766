import { default as indexqOnLrLMUjLMeta } from "/vercel/path0/pages/account/index.vue?macro=true";
import { default as indexGjYUMyRH6oMeta } from "/vercel/path0/pages/claim/index.vue?macro=true";
import { default as PoolItemc2kRken7EiMeta } from "/vercel/path0/pages/claim/items/pools/PoolItem.vue?macro=true";
import { default as Poolso5kP9i6Gg0Meta } from "/vercel/path0/pages/claim/items/pools/Pools.vue?macro=true";
import { default as PoolsGridvDCFwFv8o4Meta } from "/vercel/path0/pages/claim/items/pools/PoolsGrid.vue?macro=true";
import { default as PoolsHeaderpdpjjoljtdMeta } from "/vercel/path0/pages/claim/items/pools/PoolsHeader.vue?macro=true";
import { default as indexfgiwrlFjmpMeta } from "/vercel/path0/pages/index/index.vue?macro=true";
import { default as indexhC2dGKTbcWMeta } from "/vercel/path0/pages/lock/index.vue?macro=true";
import { default as BulletRaffleTableeWk8bNVKh8Meta } from "/vercel/path0/pages/lock/items/bullet-raffle/bullet-raffle-table/BulletRaffleTable.vue?macro=true";
import { default as TableHeader1ple3v5LuGMeta } from "/vercel/path0/pages/lock/items/bullet-raffle/bullet-raffle-table/TableHeader.vue?macro=true";
import { default as TableSummaryZfFurw6OjaMeta } from "/vercel/path0/pages/lock/items/bullet-raffle/bullet-raffle-table/TableSummary.vue?macro=true";
import { default as BulletRaffleBoxrRBEJUjqheMeta } from "/vercel/path0/pages/lock/items/bullet-raffle/BulletRaffleBox.vue?macro=true";
import { default as BulletRaffleDialogpOblwttJqEMeta } from "/vercel/path0/pages/lock/items/bullet-raffle/BulletRaffleDialog.vue?macro=true";
import { default as BulletTableqB39CHVeiVMeta } from "/vercel/path0/pages/lock/items/bullet-raffle/locked-dob/BulletTable.vue?macro=true";
import { default as InfoItem5TM3UtlsgbMeta } from "/vercel/path0/pages/lock/items/bullet-raffle/locked-dob/InfoItem.vue?macro=true";
import { default as InfoRowIOAtLql3mYMeta } from "/vercel/path0/pages/lock/items/bullet-raffle/locked-dob/InfoRow.vue?macro=true";
import { default as LockedDobquH1SzUYYXMeta } from "/vercel/path0/pages/lock/items/bullet-raffle/locked-dob/LockedDob.vue?macro=true";
import { default as MyLockedAmountZOn9NrJD7tMeta } from "/vercel/path0/pages/lock/items/bullet-raffle/locked-dob/MyLockedAmount.vue?macro=true";
import { default as MyShare9MI0XHPmjjMeta } from "/vercel/path0/pages/lock/items/bullet-raffle/locked-dob/MyShare.vue?macro=true";
import { default as TotalLockedZcAjmrLkLuMeta } from "/vercel/path0/pages/lock/items/bullet-raffle/locked-dob/TotalLocked.vue?macro=true";
export default [
  {
    name: "account___en",
    path: "/account",
    component: () => import("/vercel/path0/pages/account/index.vue")
  },
  {
    name: "account___ru",
    path: "/ru/account",
    component: () => import("/vercel/path0/pages/account/index.vue")
  },
  {
    name: "claim___en",
    path: "/claim",
    component: () => import("/vercel/path0/pages/claim/index.vue")
  },
  {
    name: "claim___ru",
    path: "/ru/claim",
    component: () => import("/vercel/path0/pages/claim/index.vue")
  },
  {
    name: "claim-items-pools-PoolItem___en",
    path: "/claim/items/pools/PoolItem",
    component: () => import("/vercel/path0/pages/claim/items/pools/PoolItem.vue")
  },
  {
    name: "claim-items-pools-PoolItem___ru",
    path: "/ru/claim/items/pools/PoolItem",
    component: () => import("/vercel/path0/pages/claim/items/pools/PoolItem.vue")
  },
  {
    name: "claim-items-pools-Pools___en",
    path: "/claim/items/pools/Pools",
    component: () => import("/vercel/path0/pages/claim/items/pools/Pools.vue")
  },
  {
    name: "claim-items-pools-Pools___ru",
    path: "/ru/claim/items/pools/Pools",
    component: () => import("/vercel/path0/pages/claim/items/pools/Pools.vue")
  },
  {
    name: "claim-items-pools-PoolsGrid___en",
    path: "/claim/items/pools/PoolsGrid",
    component: () => import("/vercel/path0/pages/claim/items/pools/PoolsGrid.vue")
  },
  {
    name: "claim-items-pools-PoolsGrid___ru",
    path: "/ru/claim/items/pools/PoolsGrid",
    component: () => import("/vercel/path0/pages/claim/items/pools/PoolsGrid.vue")
  },
  {
    name: "claim-items-pools-PoolsHeader___en",
    path: "/claim/items/pools/PoolsHeader",
    component: () => import("/vercel/path0/pages/claim/items/pools/PoolsHeader.vue")
  },
  {
    name: "claim-items-pools-PoolsHeader___ru",
    path: "/ru/claim/items/pools/PoolsHeader",
    component: () => import("/vercel/path0/pages/claim/items/pools/PoolsHeader.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/vercel/path0/pages/index/index.vue")
  },
  {
    name: "index___ru",
    path: "/ru",
    component: () => import("/vercel/path0/pages/index/index.vue")
  },
  {
    name: "lock___en",
    path: "/lock",
    component: () => import("/vercel/path0/pages/lock/index.vue")
  },
  {
    name: "lock___ru",
    path: "/ru/lock",
    component: () => import("/vercel/path0/pages/lock/index.vue")
  },
  {
    name: "lock-items-bullet-raffle-bullet-raffle-table-BulletRaffleTable___en",
    path: "/lock/items/bullet-raffle/bullet-raffle-table/BulletRaffleTable",
    component: () => import("/vercel/path0/pages/lock/items/bullet-raffle/bullet-raffle-table/BulletRaffleTable.vue")
  },
  {
    name: "lock-items-bullet-raffle-bullet-raffle-table-BulletRaffleTable___ru",
    path: "/ru/lock/items/bullet-raffle/bullet-raffle-table/BulletRaffleTable",
    component: () => import("/vercel/path0/pages/lock/items/bullet-raffle/bullet-raffle-table/BulletRaffleTable.vue")
  },
  {
    name: "lock-items-bullet-raffle-bullet-raffle-table-TableHeader___en",
    path: "/lock/items/bullet-raffle/bullet-raffle-table/TableHeader",
    component: () => import("/vercel/path0/pages/lock/items/bullet-raffle/bullet-raffle-table/TableHeader.vue")
  },
  {
    name: "lock-items-bullet-raffle-bullet-raffle-table-TableHeader___ru",
    path: "/ru/lock/items/bullet-raffle/bullet-raffle-table/TableHeader",
    component: () => import("/vercel/path0/pages/lock/items/bullet-raffle/bullet-raffle-table/TableHeader.vue")
  },
  {
    name: "lock-items-bullet-raffle-bullet-raffle-table-TableSummary___en",
    path: "/lock/items/bullet-raffle/bullet-raffle-table/TableSummary",
    component: () => import("/vercel/path0/pages/lock/items/bullet-raffle/bullet-raffle-table/TableSummary.vue")
  },
  {
    name: "lock-items-bullet-raffle-bullet-raffle-table-TableSummary___ru",
    path: "/ru/lock/items/bullet-raffle/bullet-raffle-table/TableSummary",
    component: () => import("/vercel/path0/pages/lock/items/bullet-raffle/bullet-raffle-table/TableSummary.vue")
  },
  {
    name: "lock-items-bullet-raffle-BulletRaffleBox___en",
    path: "/lock/items/bullet-raffle/BulletRaffleBox",
    component: () => import("/vercel/path0/pages/lock/items/bullet-raffle/BulletRaffleBox.vue")
  },
  {
    name: "lock-items-bullet-raffle-BulletRaffleBox___ru",
    path: "/ru/lock/items/bullet-raffle/BulletRaffleBox",
    component: () => import("/vercel/path0/pages/lock/items/bullet-raffle/BulletRaffleBox.vue")
  },
  {
    name: "lock-items-bullet-raffle-BulletRaffleDialog___en",
    path: "/lock/items/bullet-raffle/BulletRaffleDialog",
    component: () => import("/vercel/path0/pages/lock/items/bullet-raffle/BulletRaffleDialog.vue")
  },
  {
    name: "lock-items-bullet-raffle-BulletRaffleDialog___ru",
    path: "/ru/lock/items/bullet-raffle/BulletRaffleDialog",
    component: () => import("/vercel/path0/pages/lock/items/bullet-raffle/BulletRaffleDialog.vue")
  },
  {
    name: "lock-items-bullet-raffle-locked-dob-BulletTable___en",
    path: "/lock/items/bullet-raffle/locked-dob/BulletTable",
    component: () => import("/vercel/path0/pages/lock/items/bullet-raffle/locked-dob/BulletTable.vue")
  },
  {
    name: "lock-items-bullet-raffle-locked-dob-BulletTable___ru",
    path: "/ru/lock/items/bullet-raffle/locked-dob/BulletTable",
    component: () => import("/vercel/path0/pages/lock/items/bullet-raffle/locked-dob/BulletTable.vue")
  },
  {
    name: "lock-items-bullet-raffle-locked-dob-InfoItem___en",
    path: "/lock/items/bullet-raffle/locked-dob/InfoItem",
    component: () => import("/vercel/path0/pages/lock/items/bullet-raffle/locked-dob/InfoItem.vue")
  },
  {
    name: "lock-items-bullet-raffle-locked-dob-InfoItem___ru",
    path: "/ru/lock/items/bullet-raffle/locked-dob/InfoItem",
    component: () => import("/vercel/path0/pages/lock/items/bullet-raffle/locked-dob/InfoItem.vue")
  },
  {
    name: "lock-items-bullet-raffle-locked-dob-InfoRow___en",
    path: "/lock/items/bullet-raffle/locked-dob/InfoRow",
    component: () => import("/vercel/path0/pages/lock/items/bullet-raffle/locked-dob/InfoRow.vue")
  },
  {
    name: "lock-items-bullet-raffle-locked-dob-InfoRow___ru",
    path: "/ru/lock/items/bullet-raffle/locked-dob/InfoRow",
    component: () => import("/vercel/path0/pages/lock/items/bullet-raffle/locked-dob/InfoRow.vue")
  },
  {
    name: "lock-items-bullet-raffle-locked-dob-LockedDob___en",
    path: "/lock/items/bullet-raffle/locked-dob/LockedDob",
    component: () => import("/vercel/path0/pages/lock/items/bullet-raffle/locked-dob/LockedDob.vue")
  },
  {
    name: "lock-items-bullet-raffle-locked-dob-LockedDob___ru",
    path: "/ru/lock/items/bullet-raffle/locked-dob/LockedDob",
    component: () => import("/vercel/path0/pages/lock/items/bullet-raffle/locked-dob/LockedDob.vue")
  },
  {
    name: "lock-items-bullet-raffle-locked-dob-MyLockedAmount___en",
    path: "/lock/items/bullet-raffle/locked-dob/MyLockedAmount",
    component: () => import("/vercel/path0/pages/lock/items/bullet-raffle/locked-dob/MyLockedAmount.vue")
  },
  {
    name: "lock-items-bullet-raffle-locked-dob-MyLockedAmount___ru",
    path: "/ru/lock/items/bullet-raffle/locked-dob/MyLockedAmount",
    component: () => import("/vercel/path0/pages/lock/items/bullet-raffle/locked-dob/MyLockedAmount.vue")
  },
  {
    name: "lock-items-bullet-raffle-locked-dob-MyShare___en",
    path: "/lock/items/bullet-raffle/locked-dob/MyShare",
    component: () => import("/vercel/path0/pages/lock/items/bullet-raffle/locked-dob/MyShare.vue")
  },
  {
    name: "lock-items-bullet-raffle-locked-dob-MyShare___ru",
    path: "/ru/lock/items/bullet-raffle/locked-dob/MyShare",
    component: () => import("/vercel/path0/pages/lock/items/bullet-raffle/locked-dob/MyShare.vue")
  },
  {
    name: "lock-items-bullet-raffle-locked-dob-TotalLocked___en",
    path: "/lock/items/bullet-raffle/locked-dob/TotalLocked",
    component: () => import("/vercel/path0/pages/lock/items/bullet-raffle/locked-dob/TotalLocked.vue")
  },
  {
    name: "lock-items-bullet-raffle-locked-dob-TotalLocked___ru",
    path: "/ru/lock/items/bullet-raffle/locked-dob/TotalLocked",
    component: () => import("/vercel/path0/pages/lock/items/bullet-raffle/locked-dob/TotalLocked.vue")
  }
]