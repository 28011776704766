import { defineStore } from 'pinia'
import type { DOBRewardItem } from 'deorderbook-sdk/ethereum/dob_staking_pool'
import {
  dailyTotalShareBullet,
  extendLockDays as apiExtendLockDays,
  feeCollector,
  getBulletRewardThreshold,
  getHODLReward,
  getReward,
  getSniperReward,
  poolData,
  rewardDispatcher,
  userDatas,
  isCheckNFT as apiIsCheckNFT,
  stakingInfo,
  lastDeliverEndBlock as apiLastDeliverEndBlock,
  activatedOptionLength,
  activatedOptions,
  lastPeriodDailyTotalShareBullet,
  userClaimInfo as apiUserClaimInfo,
  lastWorkTimestamp as apiLastWorkTimestamp,
  lastDeliverStartBlock as apiLastDeliverStartBlock,
} from 'deorderbook-sdk/ethereum/dob_staking_pool'
import { Big } from 'big.js'
import { BaseTokenSymbol } from 'deorderbook-sdk/ethereum/token_provider'
import { balanceOf } from 'deorderbook-sdk/ethereum/hodl'
import dayjs from 'dayjs'
import { totalSupply } from 'deorderbook-sdk/ethereum/dob'
import { getDOBStakingFlows } from 'deorderbook-sdk'
import { gql } from '@urql/core'
import { onWalletReady } from '@base/composables/onWalletReady'
import type { DOBContractInfo, DOBUserInfo } from '@base/types/dob'
import { formatPercentage } from '@base/utils/number'
/**
 * Stake xHODL, xSNIPER reward DOB.
 */
export const useDOBRewardStore = defineStore('dobReward', () => {
  const rewardItems: Ref<DOBRewardItem[]> = ref([])
  const loading = ref(false)
  let updatedAt = 0
  // TODO: set a common duration
  const updateDuration = 15 * 1000

  onWalletReady(() => {
    actionRefreshItems()
  })

  const allRewardAmount = ref('0')
  const hodlRewardAmount = ref('0')
  const sniperRewardAmount = ref('0')
  const activatedOption = ref(
    [] as Awaited<ReturnType<typeof activatedOptions>>[],
  )

  function _getRewardAmount(type: 'all' | 'snipers' | 'hodls' = 'all'): string {
    let amount = Big(0)
    rewardItems.value?.forEach((item) => {
      if (type === 'all') {
        amount = amount.add(Big(item.rewardAmount))
      } else if (
        type === 'snipers' &&
        (item.from === BaseTokenSymbol.USNIPER ||
          item.from === BaseTokenSymbol.SellTokenSNIPER)
      ) {
        amount = amount.add(Big(item.rewardAmount))
      } else if (
        type === 'hodls' &&
        (item.from === BaseTokenSymbol.UHODL ||
          item.from === BaseTokenSymbol.SellTokenHODL)
      ) {
        amount = amount.add(Big(item.rewardAmount))
      }
    })
    return amount.toFixed()
  }

  function getClaimableItems(): DOBRewardItem[] {
    return rewardItems.value.filter((item) => {
      return Big(item.rewardAmount).gt(0)
    })
  }

  // [ Worker ]
  const lastDeliverStartBlock = ref('0')
  const lastDeliverEndBlock = ref('0')

  // The block in which the user made the last claim
  const userClaimBlock = ref('0')

  async function actionRefreshItems(force = false) {
    if (
      force ||
      (loading.value === false &&
        new Date().valueOf() - updatedAt >= updateDuration)
    ) {
      loading.value = true
      const uHODLRewardItem = getHODLReward(BaseTokenSymbol.UHODL)
      const sellTokenHODLRewardItem = getHODLReward(
        BaseTokenSymbol.SellTokenHODL,
      )
      const sniperRewardItems = getSniperReward()

      const optionsPList = await activatedOptionLength().then((length) => {
        return new Promise((resolve, reject) => {
          const pList = [] as ReturnType<typeof activatedOptions>[]
          for (let i = 0; i < Number(length); i++) {
            pList.push(activatedOptions(i))
          }
          Promise.all(pList)
            .then((res) => {
              resolve(res)
            })
            .catch((err) => {
              console.error(err)
              reject(err)
            })
        })
      })

      Promise.all([
        sellTokenHODLRewardItem,
        uHODLRewardItem,
        sniperRewardItems,
        optionsPList,
        apiLastDeliverStartBlock(),
        apiLastDeliverEndBlock(),
        apiUserClaimInfo(),
      ])
        .then((res) => {
          lastDeliverStartBlock.value = res[4].toString()
          lastDeliverEndBlock.value = res[5].toString()
          userClaimBlock.value = res[6].toString()

          const list: DOBRewardItem[] = []
          list.push(res[0])
          list.push(res[1])
          list.push(...res[2])
          rewardItems.value = list
          const _hodlRewardAmount = Big(res[0].rewardAmount).add(
            res[1].rewardAmount,
          )
          hodlRewardAmount.value = _hodlRewardAmount.toFixed()
          let _sniperRewardAmount = Big(0)
          res[2].forEach((sniperReward) => {
            _sniperRewardAmount = _sniperRewardAmount.add(
              sniperReward.rewardAmount,
            )
          })
          sniperRewardAmount.value = _sniperRewardAmount.toFixed()
          allRewardAmount.value = Big(_hodlRewardAmount)
            .add(_sniperRewardAmount)
            .toFixed()
          activatedOption.value = res[3] as Awaited<
            ReturnType<typeof activatedOptions>
          >[]
        })
        .catch((err) => {
          console.error(err)
        })
        .finally(() => {
          updatedAt = new Date().valueOf()
          loading.value = false
        })
    }
  }

  return {
    loading,
    lastDeliverStartBlock,
    lastDeliverEndBlock,
    rewardItems,
    activatedOption,
    getClaimableItems,
    allRewardAmount,
    hodlRewardAmount,
    userClaimBlock,
    sniperRewardAmount,
    actionRefreshItems,
  }
})

const GET_DOB_SUPPLY = gql`
  query getDobSupply($timestamp_gte: numeric, $timestamp_lte: numeric) {
    stats_circulating_supply(
      limit: 1
      order_by: { timestamp: desc }
      where: { supply: { _gt: "0" }, token: { _eq: "token-dob" } }
    ) {
      supply
      token
      timestamp
      block_height
    }
  }
`

/**
 * dob store
 */
// eslint-disable-next-line max-lines-per-function
export const useDOBStore = defineStore('dob', () => {
  const { client } = useBackendClient()

  const lastWorkTimestamp = ref('0')
  const nextWorkTimestamp = ref('0')
  const isCheckNFT = ref(false)
  const circulatingSupply = ref(0)

  const { address } = useAccount()
  const { tokenDOB } = toRefs(useTokensStore())
  const { loadingFees, hodlFees } = toRefs(useHODLStore())
  const { divDecimals } = useTokens()

  const dobContractInfo = ref<DOBContractInfo>({
    totalSupply: '0',
    collectorAddress: '',
    rewardDispatchAddress: '',
    bulletRewardThreshold: '0',
    extendLockDurations: '0',
    totalStake: '0',
    totalDailyLockDOB: '0',
    lastPeriodTotalDailyLockDOB: '0',
    collectorUHODLBalance: '0',
    collectorSellTokenHODLBalance: '0',
    rewardDispatchUHODLBalance: '0',
    rewardDispatchSellTokenHODLBalance: '0',
    totalSellTokenHODLRewardUSD: '0',
    totalUHODLRewardUSD: '0',
    apr: '0',
    aprString: '0%',
  } as DOBContractInfo)

  // user info
  const userInfo = ref<DOBUserInfo>({
    dailyStakingAmount: '0',
    totalStakingAmount: '0',
    uHODLEntryAccuReward: '0',
    sellTokenHODLEntryAccuReward: '0',
    lastEntryTimestamp: '0',
    sellTokenHODLReward: '0',
    uHODLReward: '0',
    todayLockedDobAmount: '0',
  } as DOBUserInfo)

  // Not dependent on connected wallet, call on page load
  onWalletReady(
    () => {
      actionRefreshRefs().then()
      actionRefreshDOBContractInfo().then()
    },
    {
      status: 'setup',
    },
  )

  // Dependent on connected wallet, call on connect
  onWalletReady(() => {
    actionRefreshUserDOBInfo().then()
  })

  async function actionRefreshRefs() {
    const [_lastWorkTimestamp, isNFT, supplyData] = await Promise.all([
      apiLastWorkTimestamp(),
      apiIsCheckNFT(),
      client.value.query(GET_DOB_SUPPLY, {}).toPromise(),
    ])
    lastWorkTimestamp.value = _lastWorkTimestamp.toString()
    isCheckNFT.value = isNFT
    nextWorkTimestamp.value = (
      (Number(_lastWorkTimestamp) + 24 * 60 * 60) *
      1000
    ).toString()
    circulatingSupply.value = Number(
      divDecimals(
        supplyData?.data?.stats_circulating_supply.at(0)?.supply ?? 0,
        'DOB',
      ).value ?? 0,
    )
  }

  const getLockedDobAmount = async () => {
    const lastTimestamp = await apiLastWorkTimestamp().then((res) =>
      res.toString(),
    )
    return await getDOBStakingFlows({
      where: {
        timestamp_gt: useTimestamp(Number(lastTimestamp).toString(), {
          unit: 'seconds',
        }),
        timestamp_lt: useTimestamp(
          (Number(lastTimestamp) + 24 * 60 * 60).toString(),
          {
            unit: 'seconds',
          },
        ),
        account: address.value, // wallet address
      },
    })
  }

  let userDOBInfoLastUpdateTimestamp = 0
  const userDOBInfoLoading = ref(false)
  async function actionRefreshUserDOBInfo(force = false) {
    if (!force) {
      if (dayjs().valueOf() - userDOBInfoLastUpdateTimestamp <= 15000) {
        return
      }
      if (userDOBInfoLoading.value) {
        return
      }
    }
    userDOBInfoLoading.value = true
    const info: DOBUserInfo = {} as DOBUserInfo
    await Promise.all([
      userDatas(),
      getReward(),
      stakingInfo(),
      apiLastDeliverEndBlock(),
      getLockedDobAmount(),
    ])
      .then((res) => {
        const [
          userData,
          userReward,
          stakingInfo,
          lastDeliverEndBlock,
          lockedDobAmount,
        ] = res

        // dailyStakingAmount calc logic from xy
        if (
          Number(stakingInfo.stakingAmountUpdateBlockHeight) >
          Number(lastDeliverEndBlock)
        ) {
          info.dailyStakingAmount = stakingInfo.currentStakingAmount.toString()
        } else {
          info.dailyStakingAmount = '0'
        }
        info.claimStakingAmount = stakingInfo.claimStakingAmount.toString()
        info.claimAmountUpdateBlockHeight =
          stakingInfo.claimAmountUpdateBlockHeight.toString()
        info.currentStakingAmount = stakingInfo.currentStakingAmount.toString()
        info.stakingAmountUpdateBlockHeight =
          stakingInfo.stakingAmountUpdateBlockHeight.toString()
        info.totalStakingAmount = userData.totalStakingAmount.toString()
        info.uHODLEntryAccuReward = userData.uHODLEntryAccuReward.toString()
        info.sellTokenHODLEntryAccuReward =
          userData.sellTokenHODLEntryAccuReward.toString()
        info.lastEntryTimestamp = userData.lastEntryTime.toString()
        info.uHODLReward = userReward.uHODLReward.toString()
        info.sellTokenHODLReward = userReward.sellTokenHODLReward.toString()
        info.todayLockedDobAmount = lockedDobAmount
          .reduce((acc, current) => {
            const amtBigNum = Big(current.amount)
            if (current.action === 'Stake') {
              return acc.add(amtBigNum)
            } else {
              return acc.sub(amtBigNum)
            }
          }, Big(0))
          .toFixed()
        userInfo.value = info
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        userDOBInfoLoading.value = false
        userDOBInfoLastUpdateTimestamp = dayjs().valueOf()
      })
  }

  let dobContractInfoLastUpdateTimestamp = 0
  const dobContractInfoLoading = ref(false)
  async function actionRefreshDOBContractInfo(force = false) {
    if (!force) {
      if (dayjs().valueOf() - dobContractInfoLastUpdateTimestamp <= 15000) {
        return
      }
      if (dobContractInfoLoading.value) {
        return
      }
    }
    dobContractInfoLoading.value = true
    const info: DOBContractInfo = {} as DOBContractInfo
    await Promise.all([feeCollector(), rewardDispatcher()])
      .then((res) => {
        const [feeCollector, rewardDispatcher] = res
        info.collectorAddress = feeCollector
        info.rewardDispatchAddress = rewardDispatcher.toString()
      })
      .catch((err) => {
        console.error(err)
      })
    await Promise.all([
      totalSupply(),
      getBulletRewardThreshold(),
      apiExtendLockDays(),
      poolData(),
      dailyTotalShareBullet(),
      balanceOf('uHODL', info.collectorAddress),
      balanceOf('sellTokenHODL', info.collectorAddress),
      balanceOf('uHODL', info.rewardDispatchAddress),
      balanceOf('sellTokenHODL', info.rewardDispatchAddress),
      lastPeriodDailyTotalShareBullet(), // TODO: No need to fetch them together, only used in useBulletReward
    ])
      .then((res) => {
        const [
          totalSupply,
          bulletRewardThreshold,
          extendLockDurations,
          poolData,
          totalDailyLockDOB,
          collectorUHodl,
          collectorSellTokenHODL,
          dispatcherUHodl,
          dispatcherSellTokenHODL,
          lastPeriodTotalDailyLockDOB,
        ] = res

        info.totalSupply = totalSupply.toString()
        info.bulletRewardThreshold = bulletRewardThreshold.toString()
        info.extendLockDurations = extendLockDurations.toString()
        info.totalStake = poolData.stakingAmount.toString()
        info.totalDailyLockDOB = totalDailyLockDOB.toString()
        info.lastPeriodTotalDailyLockDOB =
          lastPeriodTotalDailyLockDOB.toString()
        info.collectorUHODLBalance = collectorUHodl.toString()
        info.collectorSellTokenHODLBalance = collectorSellTokenHODL.toString()
        info.rewardDispatchUHODLBalance = dispatcherUHodl.toString()
        info.rewardDispatchSellTokenHODLBalance =
          dispatcherSellTokenHODL.toString()
        info.totalUHODLRewardUSD = Big(collectorUHodl.toString())
          .add(dispatcherUHodl.toString())
          .toFixed()
        info.totalSellTokenHODLRewardUSD = Big(
          collectorSellTokenHODL.toString(),
        )
          .add(dispatcherSellTokenHODL.toString())
          .toFixed()
        const totalStakedUSD = Big(
          divDecimals(poolData.stakingAmount.toString(), 'DOB').value,
        )
          .mul(tokenDOB.value.priceUSD)
          .toFixed()
        info.apr = calculateLockApr(totalStakedUSD)
        info.aprString = formatPercentage(info.apr)

        dobContractInfo.value = info
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        dobContractInfoLoading.value = false
        dobContractInfoLastUpdateTimestamp = dayjs().valueOf()
      })
  }

  const calculateLockAprByTime = (
    time: '24h' | '7d' | '30d',
    _totalStakedUSD?: string,
  ) => {
    const totalStakedUSD =
      _totalStakedUSD ??
      Big(divDecimals(dobContractInfo.value.totalStake, 'DOB').value)
        .times(tokenDOB.value.priceUSD)
        .toFixed()
    if (Big(totalStakedUSD).lte(0)) return '0'

    if (time === '24h') {
      // Formula: (bHODL_fee_usd_24h + uHODL_fee_usd_24h) * 365 / totalStakedUSD * 100 (in percentage)
      const vAPR = Big(
        hodlFees.value?.sellTokenHODL?.fees?.past_24h?.amount_usd ?? 0,
      )
        .add(hodlFees.value?.uHODL?.fees?.past_24h?.amount_usd ?? 0)
        .mul(365)
        .div(totalStakedUSD)
        .mul(100)
        .toFixed()
      return vAPR
    } else if (time === '7d') {
      // Formula: (bHODL_fee_usd_7d + uHODL_fee_usd_7d) / 7 * 365 / totalStakedUSD * 100 (in percentage)
      const vAPR = Big(
        hodlFees.value?.sellTokenHODL?.fees?.past_7d?.amount_usd ?? 0,
      )
        .add(hodlFees.value?.uHODL.fees?.past_7d?.amount_usd ?? 0)
        .div(7)
        .mul(365)
        .div(totalStakedUSD)
        .mul(100)
        .toFixed()
      return vAPR
    }

    // Formula: (bHODL_fee_usd_30d + uHODL_fee_usd_30d) / 30 * 365 / totalStakedUSD * 100 (in percentage)
    const vAPR = Big(
      hodlFees.value?.sellTokenHODL?.fees?.past_30d?.amount_usd ?? 0,
    )
      .add(hodlFees.value?.uHODL.fees?.past_30d?.amount_usd ?? 0)
      .div(30)
      .mul(365)
      .div(totalStakedUSD)
      .mul(100)
      .toFixed()
    return vAPR
  }

  const calculateLockApr = (_totalStakedUSD?: string) => {
    if (loadingFees.value || !hodlFees.value) {
      return '0'
    }

    const vApr24h = calculateLockAprByTime('24h', _totalStakedUSD)
    const vApr7d = calculateLockAprByTime('7d', _totalStakedUSD)
    const vApr30d = calculateLockAprByTime('30d', _totalStakedUSD)

    const avgApr = Big(vApr24h)
      .add(vApr7d)
      .add(vApr30d)
      .div(3)
      .round(2, Big.roundHalfUp)
      .toFixed()

    return avgApr
  }

  const rawLockApr = computed(() => {
    return dobContractInfo?.value?.apr ?? '0'
  })

  const lockApr = computed(() => {
    return dobContractInfo?.value?.aprString ?? '0%'
  })

  return {
    userInfo,
    isCheckNFT,
    dobContractInfo,
    dobContractInfoLoading,
    actionRefreshUserDOBInfo,
    actionRefreshDOBContractInfo,
    lastWorkTimestamp,
    nextWorkTimestamp,
    calculateLockAprByTime,
    rawLockApr,
    lockApr,
    circulatingSupply,
  }
})
