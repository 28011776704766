<template>
  <div class="y-header" :class="{ '--open': isMenuOpen }">
    <div class="header__inner">
      <div class="header__inner__left">
        <NuxtLink :to="localePath('/', locale)" aria-label="home">
          <picture>
            <source
              :srcset="HEADER_APP_LOGOS[currentApp]"
              alt="DeOrderBook Logo"
              media="(min-width: 769px)"
              loading="lazy"
              decode="async"
            />
            <img
              loading="lazy"
              decode="async"
              class="header-logo"
              :src="DobMobileLogo"
              alt="DeOrderBook Logo"
            />
          </picture>
        </NuxtLink>
        <div v-if="props.showNavLinks" class="header__desktop-wrapper">
          <YHeaderNavLinks
            :nav-links="props.navLinks"
            :current-app="currentApp"
          />
        </div>
      </div>
      <div class="header__inner__right">
        <YHeaderSettingsSelect
          v-if="props.showSettings"
          :bullet-mode-state="bulletModeState"
          @update-bullet-mode="updateBulletMode"
        />
        <YHeaderNetworkSelect v-if="props.showNetworkSelect" :chains="chains" />
        <div
          v-if="!!props.showBalance && isConnected"
          class="header__desktop-wrapper"
        >
          <YHeaderBalance :token="props.showBalance" />
        </div>
        <template v-if="showAccount">
          <YHeaderAccountButton :show-tx-history="showTxHistory" />
          <YConnectWallet />
        </template>
        <div v-if="props.showLanguage" class="header__desktop-wrapper">
          <YHeaderLanguageSelect />
        </div>
        <div class="header__mobile-wrapper">
          <SideMenuToggle :is-open="isMenuOpen" :on-toggle="toggleMenu" />
          <SideMenu :is-open="isMenuOpen">
            <YHeaderNavLinks
              :nav-links="props.navLinks"
              :current-app="currentApp"
              mobile
            />
            <YHeaderBalance
              v-if="!!props.showBalance && isConnected"
              :token="props.showBalance"
            />
            <YHeaderLanguageSelect v-if="props.showLanguage" mobile />
          </SideMenu>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { SUPPORTED_CHAINS } from 'deorderbook-sdk'
import { useBulletModeStore } from './../../store/bulletMode'
import SideMenu from './header/SideMenu.vue'
import SideMenuToggle from './header/SideMenuToggle.vue'
import type { INavLink } from './header/types'
import DobProLogo from '@base/assets/img/logos/dob-pro-logo.webp'
import DobBazaarLogo from '@base/assets/img/logos/dob-bazaar-logo.webp'
import DobExplorerLogo from '@base/assets/img/logos/dob-explorer-logo.webp'
import DobMobileLogo from '@base/assets/img/logos/dob-mobile-logo.svg'
import DobUiLogo from '@base/assets/img/logos/dob-ui-logo.svg'
import type { DeOrderBookApp } from '@base/types/app'
const props = defineProps({
  navLinks: {
    type: Array as PropType<Array<INavLink>>,
    default: () => [
      {
        path: '/',
        name: 'Home',
        active: false,
        activeClass: 'active',
        locale: 'header.home',
      } as INavLink,
    ],
  },
  showAccount: {
    type: Boolean,
    default: true,
  },
  showSettings: {
    type: Boolean,
    default: false,
  },
  showNavLinks: {
    type: Boolean,
    default: true,
  },
  showLanguage: {
    type: Boolean,
    default: false,
  },
  showNetworkSelect: {
    type: Boolean,
    default: true,
  },
  showTxHistory: {
    type: Boolean,
    default: false,
  },
  showBalance: {
    type: String as PropType<'DOB' | 'uHODL' | 'sellTokenHODL' | undefined>,
    default: undefined,
  },
  app: {
    type: String as PropType<DeOrderBookApp>,
    default: '',
  },
})
const { isConnected } = toRefs(useWalletStore())
const { value: isMenuOpen, toggle: toggleMenu } = useBoolean(false)

const { locale } = useCustomI18n()
const localePath = useLocalePath()

// [ Bullet Mode ]
const bulletModeStore = useBulletModeStore()
const updateBulletMode = (value: any) => {
  bulletModeStore.updateBulletMode(value)
}
const bulletModeState = computed(() => {
  return bulletModeStore.enabled
})
onMounted(() => {
  bulletModeStore.initialize()
})

// [ Network ]
const chains = SUPPORTED_CHAINS.map((x) => ({ ...x, isActive: true }))

// [ Logo ]
const config = useRuntimeConfig()
const currentApp = computed(() => {
  const appName = config.public.APP_SHORT_NAME as string
  return props.app || appName || 'ui'
})

const HEADER_APP_LOGOS: { [key in DeOrderBookApp]: string } = {
  ui: DobUiLogo,
  bazaar: DobBazaarLogo,
  explorer: DobExplorerLogo,
  pro: DobProLogo,
  nft: DobUiLogo,
  home: DobUiLogo,
}
</script>

<style lang="postcss" scoped>
.y-header {
  position: sticky;
  top: 0px;
  /* v-loading z-index = 2001 , Prevent being covered by v-loading */
  z-index: 2001;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-width: 100vw;
  background: linear-gradient(
    180deg,
    rgba(21, 21, 21, 1) 10%,
    rgba(0, 0, 0, 0) 100%
  );
  &.--open {
    background-color: #151515;
  }
}

.header__inner {
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1440px;
  padding: 20px 80px;
  color: #ffffff;
}

.header__inner__left {
  display: flex;
  flex: 1;
  gap: 24px;
  align-items: center;
}
.header__inner__right {
  display: flex;
  gap: 24px;
  align-items: center;
}

.header-logo {
  height: 40px;
  cursor: pointer;
}

.header__desktop-wrapper {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.header__mobile-wrapper {
  display: none;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}

@media screen and (max-width: 1024px) {
  .header__inner {
    padding: 20px 32px;
  }
}
@media screen and (max-width: 768px) {
  .header__desktop-wrapper {
    display: none;
  }
  .header__mobile-wrapper {
    display: flex;
  }
  .header__inner {
    padding: 20px 24px;
  }
  .header-logo {
    height: auto;
  }
  .header__inner__left,
  .header__inner__right {
    gap: 16px;
  }
}
@media screen and (max-width: 425px) {
  .header__inner {
    padding: 16px;
  }
}
</style>
