import type { TokenSymbols } from '@base/composables/useTokens'

export interface FlowData {
  label: string
  token: TokenSymbols
  amount: string
  usd: string
  icon: string
}

export const FLOW_LABELS = {
  IN: {
    DeOrder: 'labels.received',
    Mint: 'labels.minted',
    Unstake: 'labels.unstaked',
    HODLUnstake: 'label.sunstaked',
    Redeem: 'labels.redeemed',
    Claim: 'labels.claimed',
    Exercise: 'labels.received',
    Unwind: 'labels.received',
    Withdraw: 'labels.received',
    Collect: 'labels.received',
  },
  OUT: {
    Lock: 'labels.locked',
    Exercise: 'labels.exercised',
    Unwind: 'labels.unwind',
    Stake: 'labels.staked',
    Collect: 'labels.collected',
  },
}

// [ Detail Data]
// each action labels
export const DETAIL_LABEL_MAP = {
  DeOrder: {
    underToken: 'labels.deordered',
    outToken: 'labels.deordered-with',
    inToken: 'labels.sniper-received',
    apy: 'labels.apr',
    fees: 'labels.fees',
  },
  Mint: {
    inToken: 'labels.amount-minted',
    outToken: 'labels.price',
    fees: 'labels.fees',
  },
  Lock: {
    outToken: 'labels.amount-locked',
    lockUntil: 'labels.lock-until',
    apy: 'labels.apr',
    fees: 'labels.fees',
  },
  Stake: {
    pool: 'labels.pool',
    outToken: 'labels.amount-staked',
    apy: 'labels.apr',
    fees: 'labels.fees',
  },
  Unstake: {
    pool: 'labels.pool',
    unstaked: 'labels.amount-unstaked',
    claimed: 'labels.amount-claimed',
    apy: 'labels.apr',
    fees: 'labels.fees',
  },
  Redeem: {
    inToken: 'labels.amount-redeemed',
    outToken: 'labels.price',
    fees: 'labels.fees',
  },
  Claim: {
    inToken: 'labels.amount-claimed',
    fees: 'labels.fees',
  },
  Exercise: {
    outToken: 'labels.amount-exercised',
    outToken2: 'labels.used',
    inToken: 'labels.received',
    fees: 'labels.fees',
  },
  Unwind: {
    pool: 'labels.pool',
    outToken: 'labels.snipers-burnt',
    outToken2: 'labels.bullets-burnt',
    unwinded: 'labels.amount-unwinded',
    claimed: 'labels.amount-claimed',
    fees: 'labels.fees',
  },
  Withdraw: {
    inToken: 'labels.amount-claimed',
    fees: 'labels.fees',
  },
  Collect: {
    pool: 'labels.pool',
    outToken: 'labels.snipers-burnt',
    inToken: 'labels.amount-collected',
    claimed: 'labels.amount-claimed',
    fees: 'labels.fees',
    // FIXME: Collect need support two `inToken`, but missing in FIGMA
  },
} as const
