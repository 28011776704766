<template>
  <div v-if="!loading" class="network-select-container">
    <el-dropdown
      ref="isNetworkDropdownOpen"
      popper-class="network-select-dropdown"
    >
      <span
        class="selected-network"
        :class="!selectedNetwork.isActive ? '--invalid' : ''"
      >
        <img
          v-if="!!selectedNetwork.icon && selectedNetwork.isActive"
          :src="selectedNetwork.icon"
          alt=""
          class="network-icon"
        />
        <el-icon
          v-else-if="!selectedNetwork.isActive"
          size="20"
          class="network-warning-icon"
        >
          <Warning />
        </el-icon>
        <span class="network-name">
          {{ selectedNetwork.isActive ? selectedNetwork.name : 'Unsupported' }}
        </span>
        <el-icon class="el-icon--right">
          <el-icon-arrow-down />
        </el-icon>
      </span>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item
            v-for="chain in CHAINS"
            :key="chain.id"
            :disabled="!chain?.isActive"
            :class="
              chain.id === selectedNetwork.id && chain.isActive
                ? '--selected'
                : ''
            "
            @click="handleNetworkSelect(chain.id)"
          >
            <span class="network-item">
              <img
                v-if="!!chain.icon"
                :src="chain.icon"
                alt=""
                class="network-icon"
              />
              <div v-else class="network-icon"></div>
              {{ `${chain.name}${!chain.isActive ? ' (soon™)' : ''}` }}
            </span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>
<script lang="ts" setup>
import { Warning } from '@element-plus/icons-vue'
import type { Chain } from 'deorderbook-sdk'
import { SUPPORTED_CHAINS, ChainEnvironment, ChainId } from 'deorderbook-sdk'
import EthLogo from '@base/assets/img/ethereum-logo.svg'
import ScrollLogo from '@base/assets/img/scroll-logo.svg'

const notificationBarStore = useNotificationbarStore()
const { hideSwitchNetworkBar } = notificationBarStore
const { isNetworkDropdownOpen } = toRefs(notificationBarStore)

const { currentChain } = useChains()

const isMainnet = (env: ChainEnvironment) => {
  return env === ChainEnvironment.PROD
}

const isTestnet = (env: ChainEnvironment) => {
  return env === ChainEnvironment.TEST
}

interface IChain extends Chain {
  icon: string | null
}

const CHAINS: IChain[] = SUPPORTED_CHAINS.sort((a, b) => {
  if (isMainnet(a.environment) && isTestnet(b.environment)) {
    return -1
  }
  if (isTestnet(a.environment) && isMainnet(b.environment)) {
    return 1
  }
  return 0
}).map((chain) => ({ ...chain, icon: getNetworkIcon(chain.id) }))

const selectedNetwork = ref({} as IChain)
const loading = ref(false)

onMounted(() => {
  window?.ethereum?.on('chainChanged', () => {
    window?.location?.reload()
  })
  window?.ethereum?.on('accountsChanged', () => {
    window?.location?.reload()
  })
})

function getNetworkIcon(id: ChainId) {
  switch (id) {
    case ChainId.ETH_MAINNET:
      return EthLogo
    case ChainId.ETH_SEPOLIA:
      return EthLogo
    case ChainId.SCROLL_SEPOLIA:
      return ScrollLogo
    default:
      return null
  }
}

const handleNetworkSelect = (chainId: number) => {
  useSwitchChain(chainId, () => {
    hideSwitchNetworkBar()
  })
}

watch(
  currentChain,
  () => {
    const newChain = CHAINS.find((n) => n.id === currentChain.value?.chainId)
    if (!newChain) return
    selectedNetwork.value = newChain
  },
  {
    immediate: true,
  },
)
</script>
<style lang="postcss" scoped>
.network-select-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

.selected-network {
  display: flex;
  align-items: center;
  color: var(--yellow);
  cursor: pointer;
  outline: none;
  & .el-icon--right {
    margin-left: 8px;
    font-size: 10px;
    color: var(--yellow);
    transform: scale(1.5);
    & svg {
      width: 10px;
      height: 10px;
    }
  }
  &.--invalid {
    padding: 8px;
    background: #ff616113;
    border: 1px solid var(--red);
    border-radius: 16px;
    & .network-name {
      color: var(--red);
    }
    & .el-icon--right {
      color: var(--red);
    }
  }
}

.network-name {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
}

/* TODO: drop-down menu style repair */
:global(.network-select-dropdown .el-dropdown-menu) {
  /* Dropdown component pop-up window background color */
  background-color: #26262b;
  border-radius: 16px;
}
:global(.network-select-dropdown.el-popper.is-light) {
  /* Dropdown component pop-up window background color */
  background-color: #26262b;
  /* Dropdown component pop-up window background color */
  border: 0.5px solid #777777;
  border-radius: 16px;
}
:global(.network-select-dropdown .el-popper__arrow::before) {
  /* dropdown disable arrow */
  content: none;
}

:global(.network-select-dropdown .el-dropdown-menu__item.is-disabled) {
  & .network-item {
    opacity: 0.75;
  }
}
:global(.network-select-dropdown .el-dropdown-menu__item.--selected) {
  color: var(--yellow);
}
:global(
    .network-select-dropdown .el-dropdown-menu__item:hover:not(.is-disabled)
  ) {
  color: var(--yellow);
  background-color: transparent;
}
.network-item {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}
.network-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.network-warning-icon {
  margin-right: 4px;
  color: var(--red);
}

@media screen and (max-width: 425px) {
  .network-name {
    display: none;
  }
  .selected-network {
    & .el-icon--right {
      color: var(--white);
    }
    & .network-icon {
      margin-right: 0px;
    }
  }
}
</style>
