import { Client, fetchExchange } from '@urql/core'
import {
  ChainId,
  DEFAULT_BACKEND_URLS,
  getBackendURL,
  type BackendURLType,
} from 'deorderbook-sdk'

/**
 * Hook that returns a Hasura GraphQL client based on the current chainId. Default is mainnet.
 * Removed `cacheExchange` to be able to refetch queries
 */
export default function useBackendClient(
  options?: RequestInit | (() => RequestInit),
) {
  const config = useRuntimeConfig()
  const { chainId } = useChains()

  const client = computed(() => {
    return new Client({
      url: getBackendURL(),
      exchanges: [fetchExchange],
      fetchOptions: { ...options },
    })
  })

  const mainnetClient = computed(() => {
    return new Client({
      url: `${config.public.BACKEND_MAINNET_API_URL || DEFAULT_BACKEND_URLS[ChainId.ETH_MAINNET]}`,
      exchanges: [fetchExchange],
      fetchOptions: { ...options },
    })
  })

  const backendEnvEnums: Record<ChainId, unknown> = {
    [ChainId.ETH_MAINNET]: config.public.BACKEND_MAINNET_API_URL,
    [ChainId.ETH_SEPOLIA]: config.public.BACKEND_SEPOLIA_API_URL,
    [ChainId.SCROLL_SEPOLIA]: config.public.BACKEND_SCROLL_SEPOLIA_API_URL,
  } as const

  const clientURLFromEnv = computed(() => {
    const url = backendEnvEnums[chainId.value]
    return typeof url === 'string' && url.startsWith('http')
      ? (url as BackendURLType)
      : undefined
  })

  const clientURL = clientURLFromEnv.value ?? getBackendURL()

  return { client, clientURL, mainnetClient, clientURLFromEnv }
}
