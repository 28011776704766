import { Big } from 'big.js'
import { numberFormat } from '@base/utils'
export interface UseUSDFormat {
  /** Custom prefix for formatted numbers */
  prefix?: string
  /** Custom suffix for formatted numbers */
  suffix?: string
  /** Display approximately equal sign, default is false */
  showApprox?: boolean
}
/**
 * @description The function for formatting when displaying dollars, it formats numbers into comma-separated format, only shows two decimal places, and limits the minimum displayed number,
 * @export
 * @param {(number | string | Big)} num
 * @param {UseUSDFormat} [options={}]
 * @return {*}
 */
export default function useUSDFormat(
  num: number | string | Big,
  options: UseUSDFormat = {},
) {
  const { suffix = '', showApprox = false } = options
  // Minimum display number
  const isMinNumber = Big(num || 0).lt('0.01') && !Big(num || 0).eq(0)
  const formatNum = numberFormat(num, 'en-US', {
    dp: 2,
    minimumFractionDigits: 2,
  })
  const prefix =
    options.prefix ?? (isMinNumber ? '<$' : showApprox ? '≈ $' : '$')
  const usdString = isMinNumber
    ? '0.01'
    : formatNum === '0.00'
      ? '0'
      : formatNum

  return `${prefix}${usdString}${suffix}`
}
