<template>
  <el-switch ref="elRef" v-bind="{ ...$attrs, ...props }" class="y-switch" />
</template>
<script setup lang="ts">
import { switchProps } from 'element-plus'
const elRef = ref(null)
const props = defineProps({
  ...switchProps,
})
</script>
<style lang="postcss" scoped>
.y-switch.el-switch {
  & :deep(.el-switch__core::after) {
    top: -1px;
    display: none;
  }
  &.is-checked :deep(.el-switch__core::after) {
    right: 0;
    margin-left: -16px;
  }
  &.is-checked :deep(.el-switch__core) {
    background: #ffc550;
    border-color: #ffc550;
  }
}
</style>
