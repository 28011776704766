<template>
  <client-only>
    <div class="error-page">
      <y-header
        :show-account="false"
        :show-settings="false"
        :show-network-select="false"
        :show-balance="undefined"
        :show-language="false"
        :show-nav-links="false"
      />
      <main class="error-page__inner">
        <h1 class="error-page__title">
          Something
          <br class="--mobile-only" />
          went wrong
        </h1>
        <h2 class="error-page__code">{{ props.error?.statusCode ?? 404 }}</h2>
        <h3 class="error-page__message">Oops! DeOrderBook not found</h3>
        <p class="error-page__description">
          Let's retrace our steps and get back on track
        </p>
        <ui-button tag="a" href="/">BACK TO HOME</ui-button>
      </main>
    </div>
  </client-only>
</template>
<script setup lang="ts">
const props = defineProps({
  error: Object,
})
if (props?.error?.statusCode !== 404) {
  console.error(props)
}
</script>
<style scoped lang="postcss">
/* purgecss start ignore */
.error-page {
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  background: url('@base/assets/img/error-bg.webp') center center no-repeat;
}
.error-page__inner {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.error-page__title {
  font-size: 60px;
  font-weight: 700;
  line-height: 1.2;
  color: var(--color-brand-text-primary);
  text-align: center;
}
.error-page__code {
  margin: 24px 0 48px;
  font-size: 100px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
}
.error-page__message {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: var(--color-brand-text-secondary);
}
.error-page__description {
  margin: 8px 0 70px;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  color: var(--color-brand-text-secondary);
}

.--mobile-only {
  display: none;
}
@media screen and (max-width: 1024px) {
  .error-page__title {
    font-size: 40px;
  }
  .error-page__code {
    margin: 24px 0 48px;
    font-size: 80px;
  }
  .error-page__message {
    font-size: 22px;
    font-weight: 400;
    line-height: 26px;
  }
  .error-page__description {
    margin: 8px 0 70px;
    font-size: 14px;
    font-weight: 300;
    line-height: 18px;
  }
}

@media screen and (max-width: 768px) {
  .--mobile-only {
    display: block;
  }
  .error-page {
    background: url('@base/assets/img/error-bg.webp') center 100% no-repeat;
  }
  .error-page__title {
    font-size: 28px;
  }
  .error-page__code {
    margin: 24px 0 48px;
    font-size: 60px;
  }
  .error-page__message {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
  }
  .error-page__description {
    margin: 8px 0 70px;
    font-size: 12px;
    font-weight: 300;
    line-height: 18px;
  }
}
/* purgecss end ignore */
</style>
