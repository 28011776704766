import { defineStore } from 'pinia'

const originStore = () => {
  return {
    id: '',
    UA: '',
    loadingInstance: null as any,
    isSetOption: false,
    isMobile: false,
    isWaitMiddleware: false, // get the current route jump status, which is useful when there is asynchronous middleware
    user: {
      isLogged: false,
      walletType: '',
    },
  }
}

export const useAppStore = defineStore('app', () => {
  const store = reactive(originStore())

  function setIsMobile(value: boolean) {
    store.isMobile = value
  }

  function setIsLogged(payload: boolean, walletType: string) {
    store.user.isLogged = payload
    store.user.walletType = walletType
    if ('localStorage' in window) {
      if (payload) {
        localStorage.setItem('isLogged', payload.toString())
        localStorage.setItem('walletType', walletType)
      } else {
        localStorage.removeItem('isLogged')
        localStorage.removeItem('walletType')
        localStorage.removeItem('walletconnect')
      }
    }
  }

  return {
    setIsMobile,
    setIsLogged,
    ...toRefs(store),
  }
})
