import { defineStore } from 'pinia'
import { JsonRpcProvider, BrowserProvider } from 'ethers'
import { useLocalStorage } from '@vueuse/core'
import { ChainId, Networkish, ChainName } from 'deorderbook-sdk'
import { Network } from 'alchemy-sdk'

export interface SetDefaultProviderOptions {
  rpc?: string
  chainId?: string | ChainId
}
export interface UseChainOptions {
  /** chain id */
  id: ChainId
}

export const useChainStore = defineStore('chain', () => {
  const config = useRuntimeConfig()

  // [ Provider ]
  const defaultProvider = shallowRef(null as JsonRpcProvider | null)
  const setDefaultProvider = (options?: SetDefaultProviderOptions) => {
    const _options = options || {}
    if (!options) {
      const chain = getChainBy({
        id: Number(config.DEFAULT_CHAIN_ID ?? ChainId.ETH_MAINNET),
      })!
      _options.rpc = chain.rpc
      _options.chainId = chain.chainId
    }

    defaultProvider.value = new JsonRpcProvider(_options.rpc, _options.chainId)
  }
  // Chain data source: https://github.com/ethereum-lists/chains/tree/master/_data/chains
  // [ Chain ]
  const chains = ref([
    {
      chainId: ChainId.ETH_MAINNET,
      chainName: 'Ethereum Mainnet',
      blockExplorerUrls: 'https://etherscan.io',
      nativeCurrency: {
        name: 'Ether',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrl: config.public.MAINNET_API as string,
      rpcKey: config.public.MAINNET_API_KEY as string,
      rpc: `${config.public.MAINNET_API as string}${
        config.public.MAINNET_API_KEY as string
      }`,
      networkish: Networkish.ETH_MAINNET,
      alchemyNetwork: Network.ETH_MAINNET,
    },
    {
      chainId: ChainId.ETH_SEPOLIA,
      chainName: 'Sepolia',
      blockExplorerUrls: 'https://sepolia.etherscan.io',
      nativeCurrency: {
        name: 'Sepolia Ether',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrl: config.public.SEPOLIA_API as string,
      rpcKey: config.public.SEPOLIA_API_KEY as string,
      rpc: `${config.public.SEPOLIA_API as string}${
        config.public.SEPOLIA_API_KEY as string
      }`,
      networkish: Networkish.ETH_SEPOLIA,
      alchemyNetwork: Network.ETH_SEPOLIA,
    },
    {
      // NOTE: AlchemyProvider dose't support scroll-sepolia
      chainId: ChainId.SCROLL_SEPOLIA,
      chainName: ChainName.SCROLL_SEPOLIA,
      blockExplorerUrls: 'https://sepolia.scrollscan.com',
      nativeCurrency: {
        name: 'Ether',
        symbol: 'ETH',
        decimals: 18,
      },
      rpcUrl: 'https://sepolia-rpc.scroll.io', // config.public.SEPOLIA_API as string,
      rpcKey: '', // config.public.SEPOLIA_API_KEY as string,
      rpc: 'https://sepolia-rpc.scroll.io',
      networkish: Networkish.SCROLL_SEPOLIA,
      alchemyNetwork: undefined,
      // (config.public.SEPOLIA_API as string) +
      // (config.public.SEPOLIA_API_KEY as string),
    },
  ] as const)

  const getChainBy = (options: UseChainOptions) => {
    return chains.value.find((x) => x.chainId === options.id)!
  }

  const chainId = useLocalStorage<(typeof ChainId)[keyof typeof ChainId]>(
    'chainId',
    ChainId.ETH_MAINNET,
  )
  const currentChain = computed(() => {
    const chain = getChainBy({ id: chainId.value })
    return chain
  })
  const isReady = ref(false)

  const setCurrentChain = async (id?: ChainId) => {
    if (useIsClient()) {
      let _chainId = id
      if (window.ethereum && id === undefined) {
        const network = await new BrowserProvider(window.ethereum).getNetwork()
        _chainId = Number(network.chainId)
      }
      if (_chainId) {
        chainId.value = _chainId
      }

      // change provider
      setDefaultProvider({
        rpc: currentChain.value?.rpc,
        chainId: currentChain.value?.chainId,
      })

      isReady.value = true
    }
  }

  const rpcHost = computed(() => currentChain?.value?.rpc)

  return {
    rpcHost,
    currentChain,
    getChainBy,
    isReady,
    chains,
    chainId,
    defaultProvider,
    setDefaultProvider,
    setCurrentChain,
  }
})
