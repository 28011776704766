<template>
  <button class="side-menu-toggle" @click="props.onToggle()">
    <img
      :src="props.isOpen ? CloseIcon : HamburgerIcon"
      :alt="props.isOpen ? 'toggle-close' : 'toggle-open'"
      class="side-menu-toggle__icon"
    />
  </button>
</template>
<script setup lang="ts">
import CloseIcon from '@base/assets/img/close.svg'
import HamburgerIcon from '@base/assets/img/hamburger.svg'
const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
    default: false,
  },
  onToggle: {
    type: Function,
    required: true,
    default: () => {},
  },
})
</script>
<style lang="postcss" scoped>
.side-menu-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
}
.side-menu-toggle__icon {
  width: 24px;
  height: 24px;
}
</style>
