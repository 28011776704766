<template>
  <div class="TxHistoryListItem">
    <TxFlowInfo :data="data" />
    <TxDetailInfo :data="data" />
  </div>
</template>
<script setup lang="ts">
import { type PropType } from 'vue'
import TxFlowInfo from './TxFlowInfo.vue'
import TxDetailInfo from './TxDetailInfo.vue'
import { type FormatTrade } from '@base/types/trade'

const props = defineProps({
  data: {
    type: Object as PropType<FormatTrade>,
    default: () => ({}),
  },
})
const { data } = toRefs(props)
</script>
<style lang="postcss" scoped>
.TxHistoryListItem {
  --row1-height: 36px;
  display: grid;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  grid-template-columns: 1fr 3fr;
  gap: 16px;
  padding: 24px;
  background: #26262b;
  border: 1px solid #4d4d4d;
  border-radius: 16px;
}
@media screen and (max-width: 1024px) {
  .TxHistoryListItem {
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    grid-template-columns: 1fr;
  }
}
</style>
