import { defineStore } from 'pinia'

export const useBulletModeStore = defineStore('bulletMode', () => {
  const enabled = ref(false)

  const initialize = () => {
    if (process.client) {
      enabled.value = localStorage.getItem('IS_BULLET_MODE_ENABLED') === 'true'
    }
  }

  const updateBulletMode = (value: boolean) => {
    enabled.value = value
    if (process.client) {
      localStorage.setItem('IS_BULLET_MODE_ENABLED', value.toString())
    }
  }

  return {
    enabled,
    initialize,
    updateBulletMode,
  }
})
