<template>
  <div class="side-menu" :class="{ '--open': props.isOpen }">
    <div class="side-menu__items">
      <slot name="default"></slot>
    </div>
  </div>
</template>
<script lang="ts" setup>
const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true,
  },
})
</script>
<style lang="postcss" scoped>
.side-menu {
  display: none;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 0;
  padding: 24px;
  background-color: #151515;
  transition: height 0.3s ease-in-out;
  &.--open {
    position: fixed;
    inset: 0;
    top: 74px;
    left: 0;
    display: flex;
    height: calc(100dvh - 74px);
    & :global(body) {
      overflow: hidden;
    }
  }
  &:not(--open) {
    & :global(body) {
      overflow: auto;
    }
  }
}
.side-menu__items {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  overflow: auto;
}
</style>
