/* eslint-disable max-lines-per-function */
import { defineStore } from 'pinia'
import {
  getAllFeeRatio,
  setOnAccountChangedCallback,
  setOnChainChangedCallback,
  ChainId,
  balanceOf,
  checkChainSupport,
} from 'deorderbook-sdk'
import { parseInt } from 'lodash-es'
import { owner as getOwner } from 'deorderbook-sdk/ethereum/optionFactoryContract'
import {
  feeCollector,
  bulletCollector,
  worker,
  rewardDispatcher,
} from 'deorderbook-sdk/ethereum/dob_staking_pool'
import { owner as getOTCOwner } from 'deorderbook-sdk/ethereum/market_bullet'
import dayjs from 'dayjs'

type BalanceKey =
  | 'balanceUSDC'
  | 'balanceSellToken'
  | 'balanceUHODL'
  | 'balanceSellTokenHODL'
  | 'balanceDOB'
  | 'balanceETH'
  | 'balanceWETH'

const balanceEnum = {
  balanceUSDC: 'USDC',
  balanceSellToken: 'sellToken',
  balanceUHODL: 'uHODL',
  balanceSellTokenHODL: 'sellTokenHODL',
  balanceDOB: 'DOB',
  balanceETH: 'ETH',
  balanceWETH: 'WETH',
} as const

const originTokenBalance = () => {
  return {
    balanceUSDC: '0',
    balanceSellToken: '0',
    balanceUHODL: '0',
    balanceSellTokenHODL: '0',
    balanceDOB: '0',
    balanceETH: '0',
    balanceWETH: '0',
  }
}

export const useWalletStore = defineStore('wallet', () => {
  // [State]
  const fee = reactive({
    withdrawFeeRatio: '0',
    exerciseFeeRatio: '0',
    hodlWithdrawFeeRatio: '0',
  })
  const address = ref('')
  const approveHash = ref(null as null | string) // Record the hash of the current transaction, it should be cleared after the transaction is completed.
  const txHash = ref(null as null | string) // Record the hash of the current transaction, it should be cleared after the transaction is completed.
  const { isSetOption } = toRefs(useAppStore())
  const isConnected = ref(false)
  const tokenBalance = reactive(originTokenBalance())
  const { getTokenAddress } = useTokens()

  const isWalletModalOpen = ref(false)

  const { showSwitchNetworkBar } = useNotificationbarStore()

  const { currentChain, isReady, chainId, defaultProvider } = useChains()

  const tokenAddressEnum = {
    balanceUSDC: getTokenAddress('USDC'),
    balanceSellToken: getTokenAddress('sellToken'),
    balanceUHODL: getTokenAddress('uHODL'),
    balanceSellTokenHODL: getTokenAddress('sellTokenHODL'),
    balanceDOB: getTokenAddress('DOB'),
    balanceWETH: getTokenAddress('WETH'),
  } as const
  const balancesOf = async (_address: string, tokenAddresses: string[]) => {
    // NOTE: alchemy doesn't support Scroll
    if (chainId.value === ChainId.SCROLL_SEPOLIA) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { balanceETH, ..._balanceEnum } = balanceEnum
      const balancePromiseList = Object.keys(_balanceEnum).map(
        (key: string) => {
          const balanceKey = key as Exclude<BalanceKey, 'balanceETH'>
          return balanceOf(_balanceEnum[balanceKey], _address).then((v) => {
            return {
              contractAddress: tokenAddressEnum[balanceKey],
              tokenBalance: v.toString() || '0',
            }
          })
        },
      )
      return await Promise.all(balancePromiseList)
    } else {
      const { alchemy } = useAlchemy()
      // The wallet address / token we want to query for:
      const balances = await alchemy.value.core.getTokenBalances(
        _address,
        tokenAddresses,
      )

      return balances.tokenBalances
    }
  }

  const getWalletType = () => {
    const isClient = useIsClient()
    if (!isClient) return undefined

    if (window?.ethereum?.isMetaMask) {
      return 'metamask'
    }
    if (window?.BinanceChain) {
      return 'binanceWallet'
    }
    return undefined
  }

  const checkCurrentChain = () => {
    try {
      const chainId = currentChain.value?.chainId
      const isValidChainId = checkChainSupport(chainId)
      if (!isValidChainId || !(parseInt(String(chainId)) in ChainId)) {
        showSwitchNetworkBar()
      }
      return isValidChainId
    } catch (error) {
      return false
    }
  }
  watch(isConnected, () => {
    resetWalletBalance()
    if (!isConnected.value) return
    setBalance()
  })

  // [Actions]
  // After initiating a transaction in the wallet, methods that need to update data are put here
  const setBalance = () => {
    // set ERC20 Token Balances
    balancesOf(address.value, Object.values(tokenAddressEnum)).then(
      (balances) => {
        Object.entries(tokenAddressEnum).forEach(([token, address]) => {
          let thisTokenBalance =
            balances.find(
              (b) => b.contractAddress.toLowerCase() === address.toLowerCase(),
            )?.tokenBalance ?? '0'
          thisTokenBalance = BigInt(thisTokenBalance).toString()
          tokenBalance[token as BalanceKey] = thisTokenBalance
        })
      },
    )
    // set ETH Balance
    if (chainId.value !== ChainId.SCROLL_SEPOLIA) {
      const { alchemy } = useAlchemy()
      if (alchemy !== null) {
        alchemy.value.core.getBalance(address.value, 'latest').then((res) => {
          tokenBalance.balanceETH = res.toString()
        })
      }
    } else {
      defaultProvider.value?.getBalance(address.value).then((res) => {
        tokenBalance.balanceETH = res.toString()
      })
    }
  }

  // Restore everything except rpcHost and isSetOption
  function resetWallet() {
    Object.assign(fee, {
      withdrawFeeRatio: '0',
      exerciseFeeRatio: '0',
      hodlWithdrawFeeRatio: '0',
    })
    address.value = ''
    isConnected.value = false
    resetWalletBalance()
  }
  function resetWalletBalance() {
    Object.assign(tokenBalance, originTokenBalance())
  }

  const sniperDotEnum = {
    COLLECT: 'collect',
    EXERCISE: 'exercise',
    WALLET: 'wallet',
    SNIPERPOOL: 'sniperPool',
  }
  const sniperDot = ref({
    [sniperDotEnum.COLLECT]: {
      [sniperDotEnum.WALLET]: false,
      [sniperDotEnum.SNIPERPOOL]: false,
    },
    [sniperDotEnum.EXERCISE]: {
      [sniperDotEnum.WALLET]: false,
      [sniperDotEnum.SNIPERPOOL]: false,
    },
  })

  // [ Accounts ]
  const accounts = reactive({
    owner: '',
    feeCollector: '',
    bulletCollector: '',
    otcOwner: '',
    worker: '',
    rewardDispatcher: '',
  })
  let accountsUpdatedAt = 0

  async function actionRefreshAccounts() {
    if (accountsUpdatedAt + 15 < dayjs().unix()) {
      await Promise.all([
        getOwner(),
        feeCollector(),
        bulletCollector(),
        getOTCOwner(),
        worker(),
        rewardDispatcher(),
      ])
        .then((res) => {
          const [
            ownerAddress,
            feeCollectorAddress,
            bulletCollectorAddress,
            otcOwnerAddress,
            workerAddress,
            rewardDispatcherAddress,
          ] = res
          accounts.owner = ownerAddress
          accounts.feeCollector = feeCollectorAddress
          accounts.bulletCollector = bulletCollectorAddress
          accounts.worker = workerAddress
          accounts.otcOwner = String(otcOwnerAddress)
          accounts.rewardDispatcher = rewardDispatcherAddress.toString()
          accountsUpdatedAt = dayjs().unix()
        })
        .catch((err) => console.error(err))
    }
  }
  watch(
    isSetOption,
    () => {
      if (isSetOption.value) {
        actionRefreshAccounts()
        getAllFeeRatio().then((res) => {
          Object.assign(fee, res)
        })
        if (useIsClient()) {
          setOnAccountChangedCallback(() => {
            if (isConnected.value) {
              window.location.reload()
            }
          })
          setOnChainChangedCallback(() => {
            checkCurrentChain()
            if (isConnected.value) {
              window.location.reload()
            }
          })
        }
      }
    },
    {
      immediate: true,
    },
  )

  return {
    accounts,
    balancesOf,
    fee,
    approveHash,
    txHash,
    address,
    isSetOption,
    isConnected,
    setBalance,
    resetWallet,
    tokenBalance,
    checkCurrentChain,
    ...toRefs(tokenBalance),
    sniperDotEnum,
    sniperDot,
    isWalletModalOpen,
    getWalletType,
  }
})
