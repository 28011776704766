<template>
  <template v-if="!isConnected">
    <y-btn
      bg
      width="132"
      height="42"
      size="small"
      class="connect-wallet__button"
      @click="handleLogin"
    >
      {{ $t('labels.connect-wallet') }}
    </y-btn>
    <!-- <el-dialog
      v-model="walletVisible"
      title="CONNECT WALLET"
      :center="true"
      width="480px"
      top="28vh"
      :before-close="() => handleWalletClose()"
      class="connect-wallet__dialog"
    >
      <button
        v-for="item in walletType"
        :key="item.label"
        class="connect-wallet__dialog-item"
        @click="handleWalletSelect(item)"
      >
        <p class="connect-wallet__dialog-item__label">{{ item.label }}</p>
        <img
          class="connect-wallet__dialog-item__icon"
          :alt="item.label"
          :src="item.icon"
        />
      </button>
    </el-dialog> -->
  </template>
</template>

<script setup lang="ts">
import { ElMessage as Message } from 'element-plus'
import { WALLETS, ERROR_CODE } from '@base/utils/constants'

const { connectWallet } = useWallet()
const walletStore = useWalletStore()
const { address, isConnected } = toRefs(walletStore)

const walletVisible = ref(false)

const handleLogin = () => {
  if (address.value) return
  handleWalletSelect(WALLETS[0]) // default select MetaMask
  // walletVisible.value = true
}

const handleWalletSelect = async (wallet: (typeof WALLETS)[number]) => {
  walletVisible.value = false
  await connectWallet(wallet.id).catch((err) => {
    if (err.code === ERROR_CODE.REQUEST_PENDING) {
      useNotify({
        type: 'error',
        message:
          'Your request is currently being processed. Please wait momentarily',
      })
      return
    }

    console.error(err)
    Message.error(err.message)
  })
}
</script>

<style lang="postcss" scoped>
.connect-wallet__button {
}
.connect-wallet__dialog {
}
.connect-wallet__dialog-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 64px;
  padding: 0 16px;
  margin-bottom: 12px;
  cursor: pointer;
  background: #343434;
  border: none;
  border-radius: 4px;
  outline: none;
  transition: all 0.2s;
  &:hover {
    background: #515050;
  }
}
.connect-wallet__dialog-item__label {
}
.connect-wallet__dialog-item__icon {
  width: 32px;
  height: 32px;
}
@media screen and (max-width: 640px) {
  :global(.connect-wallet__dialog.el-dialog) {
    width: calc(100% - 32px);
  }
}
</style>
