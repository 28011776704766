/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Big } from 'big.js'
import { type Transaction, type TransactionOption } from 'deorderbook-sdk'
import type { Symbols } from 'deorderbook-sdk/types'
import { type FormatTrade, type FormatTokenFlow } from '@base/types/trade'
import { formatPercentage } from '@base/utils/number'

export function useTrades(params?: TransactionOption) {
  const store = useTradesStore()
  const { getCaches } = store
  const { fetchState } = toRefs(store)
  const { dobContractInfo, lockApr } = toRefs(useDOBStore())
  const { sellTokenHODL, uHODL } = toRefs(useHODLStore())
  const tradesOrigin = fetchState.value(params)
  const isLoading = computed(() => tradesOrigin.value.isLoading)
  const refreshTrades = tradesOrigin.value.refresh

  const { getTokenPrice, divDecimals } = useTokens()
  const { options: list, getOptionBy } = useOptions()

  // [ Sniper ]
  const { formattedPools: snipers } = toRefs(usePoolsStore())
  const { getDeorderOriginApy } = useApy()
  const apyList = computed(() => {
    return tradesOrigin.value.state.map((res) => {
      let apy = null
      if (
        (res.action.startsWith('DeOrder') || res.action === 'Unstake') &&
        res.inTokens?.find((item) => item.token?.endsWith('SNIPER'))?.address
      ) {
        const sniperAddress = res.inTokens?.find((item) =>
          item.token?.endsWith('SNIPER'),
        )?.address
        const sniper = snipers.value.find((x) => x.address === sniperAddress)
        apy = sniper?.aprString || null
      } else if (res.action === 'Lock') {
        apy = lockApr.value
      } else if (
        (res.action === 'Stake' &&
          res.outTokens?.find((item) => item?.token?.endsWith('SNIPER')) !==
            undefined) ||
        res.action === 'Collect' ||
        res.action === 'Unwind'
      ) {
        const outToken = res.outTokens?.find((item) =>
          item?.token?.endsWith('SNIPER'),
        )
        if (outToken) {
          const option = getOptionBy(list, {
            exerciseTimestamp: outToken.exerciseTimestamp!,
            strikePrice: outToken.strikePrice!,
            optionType: outToken.optionType!,
          }).value
          if (option) {
            apy = formatPercentage(getDeorderOriginApy(option).value) || null
          }
        }
      } else if (res.action === 'Claim') {
        const sniper = snipers.value.find((x) => {
          return (
            x.optionType === res.optionType &&
            x.exerciseTimestamp === res.exerciseTimestamp &&
            x.strikePrice === res.strikePrice
          )
        })
        apy = sniper?.aprString || null
      } else if (['DOBClaim', 'Withdraw'].includes(res.action)) {
        apy = lockApr.value
      } else if (
        res.action === 'HODLStake' &&
        res.outTokens?.find((item) => item?.token?.endsWith('HODL')) !==
          undefined
      ) {
        const hodlToken = res.outTokens?.find((item) =>
          item?.token?.endsWith('HODL'),
        )?.token
        apy =
          hodlToken === 'uHODL'
            ? uHODL.value.aprString
            : sellTokenHODL.value.aprString
      } else if (res.action === 'HODLUnstake') {
        const hodlToken = res.inTokens?.find((item) =>
          item?.token?.endsWith('HODL'),
        )?.token
        apy =
          hodlToken === 'uHODL'
            ? uHODL.value.aprString
            : sellTokenHODL.value.aprString
      }
      return apy
    })
  })

  const setUnderToken = (res: Transaction) => {
    let underToken: null | 'uHODL' | 'sellTokenHODL' = null
    let underTokenAmount = null
    let underTokenUSD = null
    if (
      res.action.startsWith('DeOrder') &&
      res.strikePrice !== null &&
      res.inTokens?.find((item) => item.token?.endsWith('SNIPER'))?.amount !==
        null
    ) {
      underToken = res.action === 'DeOrderSell' ? 'uHODL' : 'sellTokenHODL'
      const sniperAddress = res.inTokens?.find((item) =>
        item.token?.endsWith('SNIPER'),
      )?.address
      const sniper = snipers.value.find((x) => x.address === sniperAddress)
      if (sniper !== undefined) {
        const sniperAmount =
          res.inTokens?.find((item) => item.token?.endsWith('SNIPER'))
            ?.amount ?? 0
        underTokenAmount = (
          res.action === 'DeOrderSell'
            ? Big(sniperAmount).times(divStrikePrice(res.strikePrice))
            : Big(sniperAmount).div(divStrikePrice(res.strikePrice))
        ).toFixed() as `${number}`

        underTokenUSD = Big(
          res.action.startsWith('DeOrder')
            ? getTokenPrice(
                res.action === 'DeOrderSell' ? 'uHODL' : 'sellTokenHODL',
              ).value
            : '0',
        )
          .times(divDecimals(underTokenAmount, underToken).value)
          .toFixed()
      }
    }
    return {
      underToken,
      underTokenAmount,
      underTokenUSD,
    }
  }
  const setLabel = (res: Transaction) => {
    let label = res.action as FormatTrade['label']

    if (res.action === 'HODLUnstake') {
      label = 'Unstake'
    } else if (res.action.startsWith('DeOrder')) {
      label = 'DeOrder'
    } else if (res.action === 'DOBClaim' || res.action === 'HODLClaim') {
      label = 'Claim'
    } else if (res.action === 'HODLStake') {
      label = 'Stake'
    }

    return label
  }
  const setPool = (res: Transaction) => {
    let pool = null
    if (res.poolId) {
      pool = res.poolId === sellTokenHODL.value.id ? 'sellTokenHODL' : 'uHODL'
    } else if (res.action === 'HODLStake') {
      pool = res.outTokens?.find((item) => item.token?.endsWith('HODL'))?.token
    } else if (res.action === 'HODLUnstake') {
      pool = res.inTokens?.find((item) => item.token?.endsWith('HODL'))?.token
    } else if (
      res.strikePrice !== null &&
      res.exerciseTimestamp !== null &&
      res.optionType !== null
    ) {
      const { options: optionList } = useOptions()
      pool =
        useSniperNickname(optionList, {
          optionType: res.optionType,
          strikePrice: res.strikePrice,
          exerciseTimestamp: res.exerciseTimestamp,
        }) || null
    }
    return pool
  }
  const setLockUntil = (res: Transaction) => {
    let lockUntil = null
    if (res.action === 'Lock') {
      // TODO: extendLockDays should be the value when this data is stored, not the value when it is taken.
      lockUntil = String(
        Number(res.timestamp) +
          Number(dobContractInfo.value.extendLockDurations),
      )
    }
    return lockUntil
  }
  const outTokensPrices = computed(() => {
    return tradesOrigin.value.state.map((res) => {
      return res.outTokens.map((x) => {
        let outTokenPrice = null
        if (x.amount !== null && x.token !== null) {
          // NOTE: only SellBULLET action show OTCPrice
          if (['SellBULLET'].includes(res.action)) {
            // NOTE: OTCPrice decimals same with buyToken
            outTokenPrice = computed(
              () => divDecimals(x.OTCPrice || '0', x.OTCBuyToken!).value,
            )
          } else {
            let params
            if (
              ['SNIPER', 'BULLET'].some(
                (y) => x.token && x.token.endsWith(y),
              ) &&
              x.strikePrice !== null
            ) {
              params = {
                strikePrice: x.token.endsWith('BULLET')
                  ? divStrikePrice(x.strikePrice)
                  : x.strikePrice,
                address: x.address!,
                exerciseTimestamp: x.exerciseTimestamp || undefined,
              }
            }
            outTokenPrice = getTokenPrice(x.token, params)
          }
        }
        return outTokenPrice
      })
    })
  })
  const inTokensPrices = computed(() => {
    return tradesOrigin.value.state.map((res) => {
      return res.inTokens.map((x) => {
        let inTokenPrice = null
        if (x.amount !== null && x.token !== null) {
          // NOTE: Only SellBULLET displays OTC value.
          if (['SellBULLET'].includes(res.action)) {
            // NOTE: OTCPrice decimals same with buyToken
            inTokenPrice = computed(
              () => divDecimals(x.OTCPrice || '0', x.OTCBuyToken!).value,
            )
          } else {
            let params
            if (
              ['SNIPER', 'BULLET'].some(
                (y) => x.token && x.token.endsWith(y),
              ) &&
              x.strikePrice !== null
            ) {
              params = {
                strikePrice: x.token.endsWith('BULLET')
                  ? divStrikePrice(x.strikePrice)
                  : x.strikePrice,
                address: x.address!,
                exerciseTimestamp: x.exerciseTimestamp || undefined,
              }
            }
            inTokenPrice = getTokenPrice(x.token, params)
          }
        }
        return inTokenPrice
      })
    })
  })
  const formatTokenForDecimals = (
    token:
      | 'uSNIPER'
      | 'sellTokenSNIPER'
      | 'sellTokenHODL'
      | 'uHODL'
      | 'uBULLET'
      | 'sellTokenBULLET'
      | 'DOB'
      | null,
  ) => {
    let tokenForDecimals = token as Symbols
    if (tokenForDecimals) {
      const lowerCaseToken = tokenForDecimals.toLowerCase()
      if (lowerCaseToken.includes('sniper')) {
        tokenForDecimals = 'sniper'
      } else if (lowerCaseToken.includes('bullet')) {
        tokenForDecimals = 'bullet'
      }
    }
    return tokenForDecimals
  }
  const setOutTokens = (res: Transaction, index: number) => {
    return res.outTokens.map((x, index2: number) => {
      const tokenForDecimals = formatTokenForDecimals(x.token)
      const extraProps: { OTCUSD: string | null } = {
        OTCUSD: null,
      }
      if (['SellBULLET'].includes(res.action)) {
        const bulletParams = {
          exerciseTimestamp: x.exerciseTimestamp!,
          strikePrice: x.strikePrice!,
          address: x.address!,
        }
        extraProps.OTCUSD =
          x.OTCBuyToken && bulletParams
            ? Big(
                x.OTCPrice
                  ? getTokenPrice(x.OTCBuyToken, bulletParams).value
                  : '0',
              )
                .times(divDecimals(x.OTCPrice || '0', x.OTCBuyToken).value)
                .times(
                  divDecimals(x.amount || '0', formatTokenForDecimals(x.token))
                    .value,
                )
                .toFixed()
            : null
      }

      return {
        ...x,
        usd: x.amount
          ? Big(unref(outTokensPrices.value[index][index2]) || '0')
              .times(divDecimals(x.amount || '0', tokenForDecimals).value)
              .toFixed()
          : null,
        ...extraProps,
      }
    }) as FormatTokenFlow[]
  }
  const setInTokens = (res: Transaction, index: number) => {
    return res.inTokens.map((x, index2: number) => {
      const tokenForDecimals = formatTokenForDecimals(x.token)

      return {
        ...x,
        usd: x.amount
          ? Big(unref(inTokensPrices.value[index][index2]) || '0')
              .times(divDecimals(x.amount || '0', tokenForDecimals).value)
              .toFixed()
          : null,
        OTCUSD: null,
      }
    }) as FormatTokenFlow[]
  }

  const trades = ref<FormatTrade[]>([])
  watch(
    [
      () => tradesOrigin.value.state,
      list,
      outTokensPrices,
      inTokensPrices,
      () => dobContractInfo.value.extendLockDurations,
      apyList,
    ],
    () => {
      trades.value = tradesOrigin.value.state.map((res, index: number) => {
        const { underToken, underTokenAmount, underTokenUSD } =
          setUnderToken(res)

        const trade: FormatTrade = {
          ...res,
          label: setLabel(res),
          pool: setPool(res)!,
          underToken,
          underTokenAmount,
          underTokenUSD,
          outTokens: setOutTokens(res, index),
          inTokens: setInTokens(res, index),
          lockUntil: setLockUntil(res),
          fees: ['SellBULLET', 'BuyBULLET'].includes(res.action)
            ? '0'
            : res.fees,
          feesUSD:
            res.feeToken !== null
              ? computed(() => {
                  return Big(divDecimals(res.fees || '0', res.feeToken!).value)
                    .times(getTokenPrice(res.feeToken!).value)
                    .toFixed()
                }).value
              : null,
          fees2USD:
            res.fee2Token !== null
              ? computed(() => {
                  return Big(
                    divDecimals(res.fees2 || '0', res.fee2Token!).value,
                  )
                    .times(getTokenPrice(res.fee2Token!).value)
                    .toFixed()
                }).value
              : null,
          apy: apyList.value[index],
        }
        return trade
      })
    },
    {
      immediate: true,
      deep: true,
    },
  )

  return {
    tradesOrigin,
    trades,
    getCaches,
    isLoading,
    refreshTrades,
  }
}
