import { getTokenBySymbol } from 'deorderbook-sdk'
const DEFAULT_APP_URL = 'https://app.deorderbook.com'
const dobOriginalSymbol = getTokenBySymbol('DOB').originalSymbol
const META_DATA: {
  [key: string]: {
    title: string
    description: string
    image: string
    url: string
  }
} = {
  HOME: {
    title: 'Place a DeOrder',
    description: `Convert supported cryptocurrencies into HODL tokens, which can be used to place DeOrders and earn $${dobOriginalSymbol} rewards.`,
    image: 'deorder-page.webp',
    url: '/',
  },
  ACCOUNT: {
    title: 'Check Your DeOrderBook Account',
    description:
      'Seamlessly view and interact with all the tokens your wallet holds on the DeOrderBook protocol.',
    image: 'account-page.webp',
    url: '/account',
  },
  LOCK: {
    title: `Lock $${dobOriginalSymbol} and Claim HODL Tokens`,
    description: `Lock your $${dobOriginalSymbol} greater rewards in the form of revenue-sharing in HODL tokens. Participate in the daily BULLET Raffle (Claiming BULLETs only for holders of Gold Gerege NFT).`,
    image: 'lock-page.webp',
    url: '/lock',
  },
  CLAIM: {
    title: `Claim your $${dobOriginalSymbol} Rewards and Exercise BULLETs`,
    description: `Claim $${dobOriginalSymbol} rewards, leverage capital efficiency by unwinding DeOrders when needed, and exercise DeOrders with BULLET tokens for additional profit opportunities.`,
    image: 'claim-page.webp',
    url: '/claim',
  },
} as const

const getMetaTags = (page: string) => {
  return {
    title: META_DATA[page].title,
    description: META_DATA[page].description,
    ogType: 'website',
    ogSiteName: 'DeOrderBook',
    ogTitle: META_DATA[page].title,
    ogDescription: META_DATA[page].description,
    ogImage: DEFAULT_APP_URL + '/' + META_DATA[page].image,
    ogUrl: DEFAULT_APP_URL + META_DATA[page].url,
    twitterCard: 'summary_large_image',
    twitterTitle: META_DATA[page].title,
    twitterDescription: META_DATA[page].description,
    twitterImage: DEFAULT_APP_URL + '/' + META_DATA[page].image,
    twitterUrl: DEFAULT_APP_URL + META_DATA[page].url,
  } as { [key: string]: string }
}

const META_TAGS = {
  HOME: getMetaTags('HOME'),
  ACCOUNT: getMetaTags('ACCOUNT'),
  LOCK: getMetaTags('LOCK'),
  CLAIM: getMetaTags('CLAIM'),
} as const

export default META_TAGS
