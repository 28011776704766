<template>
  <div class="header-balance">
    <img :src="data.icon" :alt="props.token" class="header-balance__icon" />
    <p class="header-balance__value">
      {{
        useTokenNumberFormat(divDecimals(data.balance, props.token).value, {
          token: props.token,
        })
      }}
      {{ getOriginalSymbol(props.token) }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { WALLETS } from '@base/utils/constants'
const props = defineProps({
  token: {
    type: String as PropType<'DOB' | 'uHODL' | 'sellTokenHODL'>,
    default: null,
  },
})
const { divDecimals, getTokenIcon, getOriginalSymbol } = useTokens()
const { getWalletType } = useWalletStore()
const { balanceUHODL, balanceSellTokenHODL, balanceDOB } =
  toRefs(useWalletStore())

const data = computed(() => {
  const walletType = getWalletType()
  const walletIcon = WALLETS.find((w) => w.id === walletType)?.icon
  switch (props.token) {
    case 'DOB':
      return {
        balance: balanceDOB.value,
        icon: walletIcon ?? getTokenIcon('DOB'),
      }
    case 'uHODL':
      return {
        balance: balanceUHODL.value,
        icon: walletIcon ?? getTokenIcon('uHODL'),
      }
    case 'sellTokenHODL':
      return {
        balance: balanceSellTokenHODL.value,
        icon: walletIcon ?? getTokenIcon('sellTokenHODL'),
      }
    default:
      return {
        balance: 0,
        icon: '',
      }
  }
})
</script>

<style lang="postcss" scoped>
.header-balance {
  display: flex;
  align-items: center;
}
.header-balance__icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  image-rendering: -webkit-optimize-contrast;
}
.header-balance__value {
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
  color: var(--yellow);
}

@media screen and (max-width: 1024px) {
  .header-balance {
    margin-right: 16px;
  }
}

@media screen and (max-width: 425px) {
  .header-balance {
    margin-right: 12px;
  }
}
</style>
