import { defineStore } from 'pinia'
import { ref } from 'vue'
import useBoolean from '../composables/useBoolean'
import type {
  TxModalData,
  TxModalSuccessOptions,
  TxModalSuccessActionButtonParams,
} from '@base/types/transaction'
import { TxModalState } from '@base/types/transaction'
import { TX_ACTION_BUTTONS } from '@base/components/others/tx-modal/constants'

/**
 * @description This store is used to control the transaction modal
 */
export const useTxModalStore = defineStore('txModal', () => {
  const {
    value: isOpen,
    setTrue: openTxModal,
    setFalse: closeTxModal,
  } = useBoolean(false)

  const txModalState = ref<TxModalState>(TxModalState.HIDDEN)
  const txModalData = ref<TxModalData | null>(null)
  const txHash = ref<string | null>(null)
  const txError = ref<any | null>(null)
  const isAccountLinkVisible = ref(true)
  const isExplorerLinkVisible = ref(true)

  const actionButton = ref<TxModalSuccessActionButtonParams>(
    TX_ACTION_BUTTONS.ACCOUNT,
  )

  /**
   * @description Show confirm transaction modal
   * @param {TxModalData} data - Transaction modal data
   */
  const showConfirmTxModal = (data: TxModalData) => {
    txModalData.value = data
    txModalState.value = TxModalState.CONFIRM
    openTxModal()
  }

  /**
   * @description Show pending transaction modal
   */
  const showPendingTxModal = (data?: TxModalData) => {
    txModalState.value = TxModalState.PENDING
    if (data) txModalData.value = data
    if (!isOpen.value) openTxModal()
  }

  /**
   * @description Hide transaction modal
   */
  const hideTxModal = () => {
    txHash.value = null
    txError.value = null
    txModalData.value = null
    txModalState.value = TxModalState.HIDDEN
    closeTxModal()
  }

  /**
   * @description Show success transaction modal
   * @param {TxModalData} data - Transaction modal data
   * @param {string} hash - Transaction hash
   * @param {TxModalSuccessOptions} options - Transaction modal success options
   */
  const showSuccessTxModal = (
    data: TxModalData,
    hash: string,
    options?: TxModalSuccessOptions,
  ) => {
    txModalData.value = data
    txModalState.value = TxModalState.SUCCESS
    txHash.value = hash
    if (options?.showAccountLink !== undefined) {
      isAccountLinkVisible.value = options.showAccountLink
    }
    if (options?.showExplorerLink !== undefined) {
      isExplorerLinkVisible.value = options.showExplorerLink
    }
    if (options?.actionButton !== undefined) {
      actionButton.value = options.actionButton
    }
    if (!isOpen.value) openTxModal()
  }

  /**
   * @description Show failure transaction modal
   */
  const showFailureTxModal = (error?: any) => {
    if (error) {
      txError.value = error
    }
    txModalState.value = TxModalState.FAILURE
    if (!isOpen.value) openTxModal()
  }

  const updateTxHash = (hash: string) => {
    txHash.value = hash
  }

  return {
    isOpen,
    txHash,
    txError,
    txModalState,
    txModalData,
    showConfirmTxModal,
    showPendingTxModal,
    hideTxModal,
    showSuccessTxModal,
    showFailureTxModal,
    updateTxHash,
    isAccountLinkVisible,
    isExplorerLinkVisible,
    actionButton,
  }
})
