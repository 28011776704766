<template>
  <component :is="props.tag" class="button" v-bind="{ ...$attrs, ...props }">
    <slot name="default"></slot>
  </component>
</template>
<script lang="ts" setup>
const props = defineProps({
  size: {
    type: String,
    default: 'lg',
    validator: (value: string) => ['sm', 'md', 'lg'].includes(value),
  },
  color: {
    type: String,
    default: 'primary',
    validator: (value: string) => ['primary'].includes(value),
  },
  variant: {
    type: String,
    default: 'filled',
    validator: (value: string) => ['filled', 'outlined'].includes(value),
  },
  tag: {
    type: String,
    default: 'button',
    validator: (value: string) => ['button', 'a'].includes(value),
  },
})
</script>
<style lang="postcss" scoped>
/** NEW THEME COLORS FROM DEORDERBOOK-HOME */
--color-brand-primary: rgba(241, 225, 140, 1);
--color-brand-primary-dark: rgba(235, 209, 72, 1);
--color-brand-primary-light: rgba(255, 245, 190, 1);
--color-brand-primary-lighter: rgba(255, 250, 225, 1);
--color-brand-text-primary: #e4e4e4;
--color-brand-text-secondary: #ddd;

.button {
  width: max-content;
  border-radius: 86px;
  transition: all 200ms ease-in-out;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
}
.button[size='lg'] {
  width: max-content;
  padding: 20px 100px;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
  letter-spacing: 0.72px;
  border-radius: 86px;
  transition: all 200ms ease-in-out;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
}
.button[size='md'] {
  width: max-content;
  padding: 20px 100px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 0.56px;
  border-radius: 86px;
  transition: all 200ms ease-in-out;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
}
.button[size='sm'] {
  width: max-content;
  padding: 12px 48px;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 0.52px;
  border-radius: 86px;
  transition: all 200ms ease-in-out;
  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }
}
.button[color='primary'] {
}
.button[color='secondary'] {
}
.button[variant='filled'] {
  &[color='primary'] {
    color: #000000;
    background: var(--color-brand-primary);
    border: 1px solid var(--color-brand-primary);

    &:hover {
      background: var(--color-brand-primary-light);
      border-color: var(--color-brand-primary-light);
    }
    /* &:focus {
      background: var(--color-brand-primary);
      border-color: var(--color-brand-primary-lighter);
    } */
    &:active {
      background: var(--color-brand-primary-dark);
      border-color: var(--color-brand-primary-dark);
    }
    &:disabled {
      background: var(--color-brand-primary);
    }
  }
}
.button[variant='outlined'] {
  &[color='primary'] {
    color: var(--color-brand-primary);
    background: transparent;
    border: 1px solid var(--color-brand-primary);
    &:hover {
      color: var(--color-brand-primary-light);
      border-color: var(--color-brand-primary-light);
    }
    /* &:focus {
      color: var(--color-brand-primary-lighter);
      border-color: var(--color-brand-primary-lighter);
    } */
    &:active {
      color: var(--color-brand-primary-dark);
      background: rgba(255, 240, 159, 0.2);
      border-color: var(--color-brand-primary-dark);
    }
  }
}

@media screen and (max-width: 768px) {
  .button[size='lg'] {
    padding: 20px 100px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.56px;
  }
  .button[size='md'] {
    padding: 12px 48px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.52px;
  }
}

@media screen and (max-width: 425px) {
  .button[size='lg'] {
    padding: 12px 48px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.52px;
  }
}
</style>
