<template>
  <div class="TxHistoryHeader">
    <h3 class="info__title">{{ $t('header.account') }}</h3>
    <div class="info__card">
      <div class="card__address">
        <div class="address__format">
          <div ref="addressRef" style="position: absolute; z-index: -1">
            {{ address }}
          </div>
          <p :title="address">{{ addressFormat }}</p>
          <CarbonCheckmark
            v-if="isCopied"
            class="copy-icon copied"
          ></CarbonCheckmark>
          <button v-else class="copy-button" title="Copy" @click="copyAddress">
            <SVGCopy class="copy-icon"></SVGCopy>
          </button>
        </div>
        <p class="wallet__type">
          {{ $t('ui.tx-history.connected-with') }} {{ walletTypeValue }}
        </p>
      </div>
      <div class="card__explorer">
        <button class="disconnect-btn" @click="logout">
          {{ $t('ui.tx-history.disconnect') }}
        </button>
        <ExplorerLink :url="`/address/${address}`" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import ExplorerLink from '@base/components/others/ExplorerLink.vue'
import SVGCopy from '~icons/lucide/copy'
import CarbonCheckmark from '~icons/carbon/checkmark'

// User wallet address
const addressRef = ref<Node | null>(null)
const addressFormat = computed(() => {
  const _address = address.value
  return (
    _address.substring(0, 7) + '...' + _address.substring(_address.length - 4)
  )
})
const { value: isCopied, setValue: setIsCopied } = useBoolean(false)
const { copy } = useCopyText()
const copyAddress = () => {
  if (!addressRef.value) return
  copy(addressRef.value as Node)
  setIsCopied(true)
}

watch(
  () => isCopied.value,
  (newCopiedValue) => {
    if (newCopiedValue) {
      setTimeout(() => {
        setIsCopied(false)
      }, 3000)
    }
  },
)

// Wallet connection type
const { user } = toRefs(useAppStore())
const walletTypeValue = computed(() => {
  const walletTypeEnum = {
    metamask: 'MetaMask',
    walletconnect: 'WalletConnect',
  } as const
  return walletTypeEnum[user.value.walletType as 'metamask' | 'walletconnect']
})

// logout
const { address, disconnectWallet } = useWallet()
const logout = () => {
  emits('logout')
  disconnectWallet()
}
const emits = defineEmits(['logout'])
</script>
<style lang="postcss" scoped>
.TxHistoryHeader {
}
.info__title {
  margin-top: 12px;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: #fff;
}
.info__card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
  margin-top: 24px;
  background: #26262b;
  border: 1px solid #4d4d4d;
  border-radius: 16px;
}
.address__format {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #ffffff;
}
.wallet__type {
  margin-top: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #ffffff;
  opacity: 0.6;
}
.card__explorer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: right;
}
.disconnect-btn {
  padding: 6px 12px;
  margin-bottom: 4px;
  font-size: 16px;
  color: #de4238;
  cursor: pointer;
  background-color: transparent;
  border: none;
  border-radius: 8px;
  outline: none;
  transition: all 200ms ease-in-out;
  &:hover {
    background: #de423811;
  }
}
.copy-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  color: rgba(255, 255, 255, 0.4);
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  transition: color 100ms;
  &:hover {
    color: rgba(255, 255, 255, 0.8);
  }
}
.copy-icon {
  width: 20px;
  height: 20px;

  &.copied {
    margin-left: 8px;
    color: rgba(255, 255, 255, 0.8);
  }
}
:global(.copy-tooltip.el-popper) {
  padding: 2px 4px;
  font-size: 12px;
  background: #212126;
  border-radius: 4px;
}
:global(.copy-tooltip.el-popper .el-popper__arrow::before) {
  background-color: #212126;
}
@media screen and (max-width: 1024px) {
  .info__title {
    margin-top: 10px;
    font-size: 20px;
    line-height: 1.25;
  }
  .info__card {
    padding: 12px 24px;
    margin-top: 20px;
  }
  .address__format {
    font-size: 18px;
    line-height: 1.25;
  }
}

@media screen and (max-width: 425px) {
  .info__title {
    font-size: 18px;
  }
  .info__card {
    padding: 8px 16px;
    margin-top: 16px;
  }
  .address__format {
    font-size: 16px;
  }
  .wallet__type {
    margin-top: 8px;
    font-size: 12px;
    line-height: 14px;
  }
  .card__explorer {
    font-size: 12px;
    line-height: 1.25;
  }
  .disconnect-btn {
    margin-bottom: 8px;
  }
}
</style>
