<template>
  <div class="nav-links" :class="{ '--centered': currentApp === 'ui' }">
    <NuxtLink
      v-for="item in props.navLinks"
      :key="item.name"
      :to="localePath(item.path)"
      class="nav-links__item"
      :class="{ active: getRouteActive(item) }"
      :active-class="item.activeClass"
      :target="item.target ?? '_self'"
    >
      {{ $t(item.locale) }}
    </NuxtLink>
    <template v-if="currentApp === 'ui'">
      <YHeaderMoreNavLinks :mobile="props.mobile" />
    </template>
  </div>
</template>
<script setup lang="ts">
import type { INavLink } from './types'
import type { DeOrderBookApp } from '@base/types/app'

const props = defineProps({
  navLinks: {
    type: Array as PropType<Array<INavLink>>,
    required: true,
  },
  currentApp: {
    type: String as PropType<DeOrderBookApp>,
    required: true,
  },
  mobile: {
    type: Boolean,
    default: false,
  },
})
const router = useRouter()
const localePath = useLocalePath()

// If it is currently a child route, add active to the parent route
const getRouteActive = (item: INavLink) => {
  if (item.active) return true
  if (item.path === '/' && toValue(router.currentRoute).path !== '/')
    return false
  return toValue(router.currentRoute).fullPath.startsWith(item.path)
}
</script>

<style lang="postcss" scoped>
.nav-links {
  display: flex;
  flex-direction: row;
  gap: 32px;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  &.--centered {
    justify-content: center;
  }
}
.nav-links__item {
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: var(--yellow);
  opacity: 0.75;
  transition: all 200ms ease-in-out;

  &.active,
  &:hover {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .nav-links {
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: flex-start;
  }
}
</style>
